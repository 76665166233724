import React from "react";
import api from "../../Shared/Functions/APIHelpers";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Agreement from "../../Shared/Components/Agreement/Agreement";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import _1095DoneMark from "./1095DoneMark";
import _1095InfoCard from "./1095InfoCard";
import Paper from "@material-ui/core/Paper";
import EINTextBox, {
  mapEINToMask,
  outputEINFromMask,
} from "../../Shared/Components/Input/EINTextBox";

const initialState = {
  loaded: false,
  fileEin: "",
  fileName: "",
  ein: "",
  name: "",
  finished: false,
};

class _1095Confirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidMount() {
    var portalID = this.getPortalID();
    var _1095ID = this.get1095ID();
    this.setState({ portalID: portalID, _1095ID: _1095ID });

    this.get1095RecordInfo(_1095ID).then((r) => {
      this.setState({ fileName: r.name, fileEin: r.ein });
      this.setState({ loaded: true });
    });
  }

  getPortalID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get("portalID") ? params.get("portalID") : null;
  };

  get1095ID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get("1095ID") ? params.get("1095ID") : null;
  };
  get1095ID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get("1095ID") ? params.get("1095ID") : null;
  };

  handleUpdateRecord = () => {
    this.update1095Record(this.state.portalID, this.state._1095ID).then(
      (resulte) => {
        this.setState({ finished: true });
      }
    );
  };

  update1095Record = (portalID, _1095ID) => {
    let body = {
      _1095ID: _1095ID,
      name: this.state.name,
      ein: outputEINFromMask(this.state.ein).conformedValue,
    };
    return new Promise((resolve, reject) => {
      if (this.props.employerIsSecured)
        resolve(api.confirm1095RecordSecured(portalID, JSON.stringify(body)));
      else resolve(api.confirm1095Record(portalID, JSON.stringify(body)));
    });
  };

  get1095RecordInfo = (_1095ID) => {
    return new Promise((resolve, reject) => {
      if (this.props.employerIsSecured)
        resolve(api.confirm1095RecordInfoSecured(_1095ID));
      else resolve(api.confirm1095RecordInfo(_1095ID));
    });
  };

  render() {
    const { loaded, finished } = this.state;

    if (!loaded) return null;
    if (finished) return <_1095DoneMark />;
    return (
      <React.Fragment>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box width={500}>
            <Typography variant="h6" style={{ marginTop: 20 }}>
              Please Confirm Company Name and EIN
            </Typography>

            <_1095InfoCard
              fileName={this.state.fileName}
              fileEin={this.state.fileEin}
            />
            <TextField
              id="employer-name-text-field"
              label="Employer Name"
              margin="dense"
              onChange={(e) => {
                this.setState({ name: e.target.value });
              }}
              value={this.state.name}
              fullWidth
            />
            <FormControl fullWidth style={{ marginTop: 10 }}>
              <InputLabel htmlFor="formatted-text-mask-input">EIN</InputLabel>
              <Input
                value={this.state.ein}
                onChange={(e) => this.setState({ ein: e.target.value })}
                name="textmask"
                id="formatted-text-mask-input"
                inputComponent={EINTextBox}
              />
            </FormControl>
          </Box>

          <Button
            variant="contained"
            color="primary"
            disabled={!this.state.ein || !this.state.name}
            onClick={() => {
              this.handleUpdateRecord(false);
            }}
            style={{ width: 250, marginTop: 30, marginBottom: 20 }}
          >
            Submit
          </Button>
        </Box>
      </React.Fragment>
    );
  }
}

export default _1095Confirm;
