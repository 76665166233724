import React from "react";

import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Auth } from "aws-amplify";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  logoContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  largeLogo: {
    height: 700,
  },
});

class _1095InfoCard extends React.Component {
  state = {
    signerFirst: "",
    signerLast: "",
  };

  render() {
    return (
      <React.Fragment>
        <Card style={{ marginBottom: 25, marginTop: 25 }}>
          <CardHeader
            subheader="Original Filing Data"
            style={{ backgroundColor: "#FFCC00" }}
          />
          <CardContent>
            <Typography
              variant="body2"
              style={{ marginTop: 2, marginBottom: 2 }}
            >
              Company Name: <b>{this.props.fileName}</b>
              <br />
              EIN: <b>{this.props.fileEin}</b>
            </Typography>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(_1095InfoCard));
