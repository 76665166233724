import React from "react";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ContainerStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";

import ArcForm from "../../Shared/Components/Arc/ArcForm";
import SubmitArcRequestDialog from "./SubmitArcRequestDialog";
import { calculateAutoFillFields } from "../../Shared/Components/Arc/ArcHelpers";
import api from "../../Shared/Functions/APIHelpers";

const initialState = {
  arcRequest: {},
  errors: [],
  loaded: false,
  arcRequestSaving: false,
  confirmSubmit: false,
  uploadedFiles: [],
  requestFieldFocus: "",
  submitArcRequestDialog: false,
  allowSupportingDocumentation: false,
  needSupportingDocumentation: false,

  validArcRequestID: true,
  validContactID: true,
};

class ArcContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidMount() {
    let arcRequestID = this.getArcRequestID();
    let contactID = this.getContactID();
    if (arcRequestID && contactID) {
      this.getArcRequest(arcRequestID).then((r) => {
        this.setState({
          arcRequest: calculateAutoFillFields(r),
          uploadedFiles: r.saved_supporting_documentation.map((f) => {
            return {
              name: f.name,
              friendly_name: f.name,
            };
          }),
          allowSupportingDocumentation: r.arc_supporting_documentation,
          needSupportingDocumentation: r.require_arc_supporting_documentation,
          loaded: true,
        });
      });
    }
    /*
    this.getContacts(this.getContactID())
      .then((r) => {
        this.setState({ contacts: r });
      })
      .catch((err) => {
        this.showUnauthorizedWarning("Must Be a Primary Contact To View");
        this.setState({ validContactID: false });
      });*/
  }

  getArcRequestID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let arcRequestID = params.get("arcID") ? params.get("arcID") : null;
    if (!arcRequestID) {
      this.showUnauthorizedWarning("Must Provide a Valid ARC ID");
      this.setState({ validArcRequestID: false });
    }
    return arcRequestID;
  };

  getContactID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let contactID = params.get("contactID") ? params.get("contactID") : null;
    if (!contactID) {
      this.showUnauthorizedWarning("Must Provide a Valid Contact ID");
      this.setState({ validContactID: false });
    }
    return contactID;
  };

  showUnauthorizedWarning = (message) => {
    this.props.enqueueSnackbar(message, {
      variant: "warning",
    });
  };

  getArcRequest = (arcRequestID) => {
    return new Promise((resolve, reject) => {
      resolve(api.getBrokerArcRequest(arcRequestID));
    });
  };

  handleSave = () => {
    //if (this.inError()) return;
    this.setState({ arcRequestSaving: true });
    this.handleGetPresignedURLs().then((r) => {
      this.uploadFiles(r.urls).then((r) => {
        this.updateBrokerArcRequest(false).then((r) => {
          this.setState({
            arcRequestSaving: false,
            submitArcRequestDialog: true,
          });
        });
      });
    });
  };

  handleSubmit = () => {
    if (this.inError()) {
      this.props.enqueueSnackbar("Please complete all required fields", {
        variant: "error",
      });
      return;
    }
    this.setState({ arcRequestSaving: true });
    this.handleGetPresignedURLs().then((r) => {
      this.uploadFiles(r.urls)
        .then((r) => {
          this.updateBrokerArcRequest(true)
            .then((r) => {
              this.setState({
                arcRequestSaving: false,
                submitArcRequestDialog: true,
              });
            })
            .catch((err) => {
              if (err === 403) {
                this.props.history.push("/mewa-admin/auth?error=5");
              }
              if (err === 400) {
                this.props.enqueueSnackbar(
                  "Something went wrong saving ARC Request",
                  {
                    variant: "error",
                  }
                );
                this.setState({
                  arcRequestSaving: false,
                });
              }
            });
        })
        .catch((err) => {
          this.props.enqueueSnackbar(
            "There was an issue uploading supporting documentation. Please try again.",
            {
              variant: "error",
            }
          );
          this.setState({
            arcRequestSaving: false,
          });
        });
    });
  };

  updateBrokerArcRequest = (isComplete) => {
    let body = this.state.arcRequest;
    body.is_complete = false;

    //filter blank fields from objects (strings and nulls) becaue this isnt a submission
    Object.entries(body).map((field) => {
      if (field[1] === "") {
        body[field[0]] = null;
      }
      if (field[1] < 0) {
        body[field[0]] = null;
      }
    });

    //ensure perecentage participating is 2 decimals only
    if (body.percentage_participating_plan) {
      body.percentage_participating_plan =
        body.percentage_participating_plan.toFixed(2);
    }

    //only add supportinDocumentation key to body if there were documents uploaded
    if (this.state.uploadedFiles.length) {
      body.supporting_documentation = this.state.uploadedFiles.map(
        (f) => f.friendly_name
      );
    } else {
      delete body.supporting_documentation;
    }
    if (isComplete) {
      body.is_complete = true;
    }
    return new Promise((resolve, reject) => {
      resolve(
        api.updateBrokerArcRequest(this.getArcRequestID(), JSON.stringify(body))
      );
    });
  };

  inError = () => {
    let errors = [];
    const body = this.state.arcRequest;
    if (this.state.arcRequest.has_employees) {
      //first filter out unrequired fields or auto calculated fields
      Object.entries(body)
        .filter((f) => f[0] !== "id")
        .filter((f) => f[0] !== "broker_assistance_requested")
        .filter((f) => f[0] !== "signature_date")
        .filter((f) => f[0] !== "sig_form_name")
        .filter((f) => f[0] !== "EIN")
        .filter((f) => f[0] !== "title")
        .filter((f) => f[0] !== "total_employees_wage_and_tax")
        .filter((f) => f[0] !== "part_time_employees")
        .filter((f) => f[0] !== "full_time_employees")
        .filter((f) => f[0] !== "medicare_status")
        .filter((f) => f[0] !== "chamber_name")
        .filter((f) => f[0] !== "chamber_start_date")
        .filter((f) => f[0] !== "chamber_end_date")
        .filter((f) => f[0] !== "date_verified")
        .filter((f) => f[0] !== "date_closed")
        .filter((f) => f[0] !== "date_last_reminder_sent_employer")
        .filter((f) => f[0] !== "date_last_reminder_sent_broker")
        .filter((f) => f[0] !== "carrier_agent_id")
        .filter((f) => f[0] !== "date_broker_notified")
        .filter((f) => f[0] !== "net_change_employees")
        .filter((f) => f[0] !== "full_time_employees_50_percent")
        .filter((f) => f[0] !== "part_time_hours")
        .filter((f) => f[0] !== "full_time_employee_hours")
        .filter((f) => f[0] !== "number_fte_from_part_time")
        .filter((f) => f[0] !== "full_time_equivalent_employees")
        .filter((f) => f[0] !== "max_combined_full_part_time_20_weeks")
        .filter((f) => f[0] !== "average_premium_per_employee")
        .filter((f) => f[0] !== "average_contribution_rate_per_employee")
        .filter((f) => f[0] !== "rate_description")
        .filter((f) => f[0] !== "plan_year")
        .filter((f) => f[0] !== "broker_first")
        .filter((f) => f[0] !== "broker_last")
        .filter((f) => f[0] !== "broker_email")
        .filter((f) => f[0] !== "broker_contact")
        .filter((f) => f[0] !== "arc_event")
        .filter((f) => f[0] !== "suite")
        .filter((f) => f[0] !== "signature_file")
        .filter((f) => f[0] !== "signature_version")
        .filter((f) => f[0] !== "closed_by")
        .filter((f) => f[0] !== "date_broker_verified")
        .filter((f) => f[0] !== "employer_contact_last")
        .filter((f) => f[0] !== "employer_contact_first")
        .filter((f) => f[0] !== "date_sent")
        .filter((f) => f[0] !== "date_opened")
        .filter((f) => f[0] !== "arc_event_start_date")
        .filter((f) => f[0] !== "original_continuation_status")
        .filter((f) => f[0] !== "street")
        .filter((f) => f[0] !== "city")
        .filter((f) => f[0] !== "zip")
        .filter((f) => f[0] !== "state")
        .map((field) => {
          if (field[1] === "") errors.push(field[0]);
          if (field[1] === null) errors.push(field[0]);
        });

      if (Number.isNaN(body.employer_contribution_percentage)) {
        errors.push("employer_contribution_percentage");
      }
    }

    /*
    //even if you have employees we still want to ensure employer address is completed
    if (
      this.state.arcRequest.street === null ||
      this.state.arcRequest.street === ""
    ) {
      errors.push("street");
    }
    if (
      this.state.arcRequest.city === null ||
      this.state.arcRequest.city === ""
    ) {
      errors.push("city");
    }
    if (
      this.state.arcRequest.state === null ||
      this.state.arcRequest.state === ""
    ) {
      errors.push("state");
    }
    if (
      this.state.arcRequest.zip === null ||
      this.state.arcRequest.zip === ""
    ) {
      errors.push("zip");
    }
    */

    //if this is MCF we need to ensure that chamber_name is filled out
    if (this.state.arcRequest.arc_mewa === "9ac40fe874e84df5b81288daf45de042") {
      if (
        this.state.arcRequest.chamber_name === "" ||
        this.state.arcRequest.chamber_name === null
      ) {
        errors.push("chamber_name");
      }
    }

    //if this is MCF we need to ensure that chamber_start_date
    if (this.state.arcRequest.arc_mewa === "9ac40fe874e84df5b81288daf45de042") {
      if (
        this.state.arcRequest.chamber_start_date === "" ||
        this.state.arcRequest.chamber_start_date === null
      ) {
        errors.push("chamber_start_date");
      }
    }
    //if this is MCF we need to ensure that chamber_end_date
    if (this.state.arcRequest.arc_mewa === "9ac40fe874e84df5b81288daf45de042") {
      if (
        this.state.arcRequest.chamber_end_date === "" ||
        this.state.arcRequest.chamber_end_date === null
      ) {
        errors.push("chamber_end_date");
      }
    }

    if (
      this.state.uploadedFiles.length === 0 &&
      this.state.needSupportingDocumentation
    ) {
      errors.push("uploadedFiles");
    } else {
      errors = errors.filter((e) => e !== "uploadedFiles");
    }
    this.setState({ errors: errors });
    console.log(errors);
    return errors.length > 0;
  };

  handleGetPresignedURLs = () => {
    var body = JSON.stringify({
      requested_presigned_urls: this.state.uploadedFiles.map(
        (f) => f.friendly_name
      ),
      arc_request: this.state.arcRequest.id,
    });

    return new Promise((resolve, reject) => {
      resolve(api.getSupportingDocumentationARCPresignedBroker(body));
    });
  };

  uploadFiles = (s3Files) => {
    return new Promise((resolve, reject) => {
      let uploadJobs = [];
      //For each presigned url, we need to find the file in state and upload it
      s3Files.forEach((f) => {
        uploadJobs.push(
          new Promise((resolve, reject) => {
            var s3Fields = f.fields;
            const formData = new FormData();

            Object.keys(s3Fields).forEach((key) => {
              formData.append(key, s3Fields[key]);
            });
            formData.append(
              "file",
              this.state.uploadedFiles.find(
                (file) => file.friendly_name === f["frontend_name"]
              )
            );
            fetch(f.url, {
              method: "POST",
              body: formData,
            })
              .then((r) => {
                resolve(r);
              })
              .catch((e) => {
                console.error(e);
              });
          })
        );
      });

      Promise.all(uploadJobs).then((values) => {
        resolve("done");
      });
    });
  };

  addUploadedFiles = (files) => {
    let currentFiles = this.state.uploadedFiles;

    //For each file name uploaded, create a friendly name
    //field on the FILE object in memory that will drop any bad characters
    let regex = /[/:*?"<>|]/g;
    files.forEach((f) => {
      f.friendly_name = f.name.replace(regex, "");
      currentFiles.push(f);
    });

    this.setState({ uploadedFiles: currentFiles, supportingDialogOpen: false });
  };

  removeFile = (file) => {
    let currentFiles = this.state.uploadedFiles;
    currentFiles = currentFiles.filter((f) => f.name !== file.name);
    this.setState({ uploadedFiles: currentFiles });
  };

  updateRequestField = (field, value) => {
    let request = this.state.arcRequest;
    request[field] = value;
    this.setState({ arcRequest: request });
  };

  render() {
    const { classes } = this.props;
    const { validArcRequestID } = this.state;

    if (!validArcRequestID) return null;
    return (
      <React.Fragment>
        <SubmitArcRequestDialog
          Open={this.state.submitArcRequestDialog}
          Employer={this.state.arcRequest.employer_name}
        />
        <Container maxWidth="sm">
          <div className={classes.title} style={{ marginBottom: 20 }}>
            <Typography variant="h4" id="arcRequestTitle">
              ARC Form
            </Typography>
            <Typography variant="body1" id="arcRequestTitle">
              Annual Rate and Census Reconcilliation (ARC) Form
            </Typography>
          </div>
          <div style={{ marginTop: 10, marginBottom: 20 }}>
            <Typography variant="caption">
              The ARC Form assists the plan administrator in completing the
              MEWA's annual Form 5500 which is required by ERISA.
            </Typography>
          </div>

          <div style={{ marginTop: 10, marginBottom: 50 }}>
            <Typography variant="h6">Employer</Typography>
            <Typography variant="body1" id="arcRequestTitle">
              {this.state.arcRequest.employer_name}
            </Typography>
          </div>

          {this.state.loaded && (
            <React.Fragment>
              {!this.state.arcRequest.date_verified && (
                <ArcForm
                  arcRequest={this.state.arcRequest}
                  calculateAutoFillFields={() => {
                    this.setState({
                      requestFieldFocus: "",
                      request: calculateAutoFillFields(this.state.arcRequest),
                    });
                  }}
                  updateRequestFieldFocus={(field) => {
                    this.setState({ requestFieldFocus: field });
                  }}
                  hideDocuments={!this.state.allowSupportingDocumentation}
                  requestFieldFocus={this.state.requestFieldFocus}
                  uploadedFiles={this.state.uploadedFiles}
                  addUploadedFiles={this.addUploadedFiles}
                  removeFile={this.removeFile}
                  updateRequestField={this.updateRequestField}
                  save={null}
                  hideSave={true}
                  arcRequestSaving={this.state.arcRequestSaving}
                  errors={this.state.errors}
                  theme={this.props.theme}
                />
              )}
              {this.state.arcRequest.date_verified && (
                <div style={{ textAlign: "center", marginTop: 50 }}>
                  <Typography variant="body1">
                    This ARC Form has already been submitted.
                  </Typography>
                </div>
              )}
            </React.Fragment>
          )}

          {!this.state.confirmSubmit &&
            !this.state.arcRequest.date_verified && (
              <div
                style={{
                  display: "flex",
                  marginTop: 50,
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  onClick={() => {
                    this.setState({ confirmSubmit: true });
                  }}
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
                <div className={this.props.classes.wrapper}>
                  <Button
                    onClick={this.handleSave}
                    disabled={this.state.arcRequestSaving}
                    color="primary"
                    id="save-arc-button"
                  >
                    Save
                  </Button>
                  {this.state.arcRequestSaving && (
                    <CircularProgress
                      size={24}
                      className={this.props.classes.buttonProgress}
                    />
                  )}
                </div>
              </div>
            )}
          {this.state.confirmSubmit && !this.state.arcRequest.date_verified && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                marginTop: 50,
              }}
            >
              <Typography variant="body1" id="areYouSureTitle">
                Are you sure you wish to submit?
              </Typography>

              <div className={this.props.classes.wrapper}>
                <Button
                  size="small"
                  variant="contained"
                  onClick={this.handleSubmit}
                  disabled={this.state.arcRequestSaving}
                  color="primary"
                  style={{ marginLeft: 20, marginRight: 10 }}
                >
                  Yes
                </Button>
                {this.state.arcRequestSaving && (
                  <CircularProgress
                    size={24}
                    className={this.props.classes.buttonProgress}
                  />
                )}
              </div>
              <div className={this.props.classes.wrapper}>
                <Button
                  size="small"
                  onClick={() => {
                    this.setState({ confirmSubmit: false });
                  }}
                  variant="contained"
                  style={{
                    marginRight: 20,
                    color: this.props.theme.palette.error.main,
                  }}
                >
                  No
                </Button>
              </div>
            </div>
          )}
        </Container>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ArcContainer);
