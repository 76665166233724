import api from "../../Functions/APIHelpers";

export function loadPlacesScript(callback) {
  //ensure script isn't already loaded
  let existingScript = document.getElementById("googleMaps");
  let url =
    "https://maps.googleapis.com/maps/api/js?key=AIzaSyCLVaKkdzFqRdlbQWSE_bjt3bCFrBaIjUk&libraries=places";
  //If script doesn't exist, load it and append it to document body
  if (!existingScript) {
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    script.id = "googleMaps";
    document.body.appendChild(script);

    //once script is loaded execute callback script
    //so that calling code knows what to do next
    //we know script is loaded using the provided onload() func
    script.onload = () => {
      if (callback) callback();
    };
  }

  //if script already exists just hit the callback
  if (existingScript && callback) callback();
}

export function formatGoogleReturnAddress(addressComponent) {
  //following example in docs for field names
  //https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
  let returnObject = {};
  addressComponent.address_components.forEach((a) => {
    let componentType = a.types[0];
    let componentValueLong = a.long_name;
    let componentValueShort = a.short_name;
    if (componentType === "street_number") {
      returnObject.streetNumber = componentValueLong;
    }
    if (componentType === "route") {
      returnObject.streetName = componentValueLong;
    }
    if (componentType === "locality") {
      returnObject.city = componentValueLong;
    }
    if (componentType === "administrative_area_level_1") {
      returnObject.state = componentValueShort;
    }
    if (componentType === "postal_code") {
      returnObject.zip = componentValueLong;
    }
  });

  return returnObject;
}

export function fieldIsEmpty(field) {
  if (field === "") return true;
  if (field === 0) return false;
  if (!field) return true;
}

export function calculateAutoFillFields(request) {
  /*
      AUTOCALC FIELDS
      net_eligible_employees = total_eligible_employees - qualified_waivers + continuants
      percentage_participating_plan = net_enrolled_employees / net_eligible_employees
    */

  // NET ELIGIBLE EMPLOYEES CALC //
  if (
    !fieldIsEmpty(request.total_eligible_employees) &&
    !fieldIsEmpty(request.qualified_waivers) &&
    !fieldIsEmpty(request.continuants)
  )
    request["net_eligible_employees"] =
      parseInt(request.total_eligible_employees) -
      parseInt(request.qualified_waivers) +
      parseInt(request.continuants);
  else request["net_eligible_employees"] = null;

  // PERCENTAGE PARTICIPATING CALC //
  if (
    !fieldIsEmpty(request.net_enrolled_employees) &&
    !fieldIsEmpty(request.net_eligible_employees)
  ) {
    let val = Number(
      (
        Math.floor(parseFloat(request.net_enrolled_employees)) /
        parseFloat(request.net_eligible_employees)
      ).toFixed(2)
    );
    //precheck to see if net eligible is zero BUT net enrolled = total employees
    if (
      request.net_eligible_employees === 0 &&
      request.net_enrolled_employees > 0
    ) {
      request["percentage_participating_plan"] = 1;
    }
    //Ensure the value isFinite to avoid division by zero
    else if (isFinite(val)) {
      request["percentage_participating_plan"] = val;
    }
    //If net enrolled or net eligible is a legitimate zero value, but the division is non finite, allow percent participating to be zero
    else if (
      !isFinite(val) &&
      (request.net_enrolled_employees === 0 ||
        request.net_eligible_employees === 0)
    ) {
      request["percentage_participating_plan"] = 0;
    } else {
      request["percentage_participating_plan"] = null;
    }

    //Added check for exceeding 100%
  } else request["percentage_participating_plan"] = null;

  return request;
}

export function getHelperText(field, arcRequest) {
  // User-entered fields...........
  // Total Employees
  if (field === "total_eligible_employees")
    return (
      "Enter the number of eligible employees on your company’s most recent wage and tax statement. An eligible employee is an employee of the Plan Sponsor who is regularly scheduled to work a minimum of " +
      arcRequest.mewa_full_time_hours +
      " hours per week. If you do not have a wage and tax statement, the following forms may be used: Form 1040 with Schedule C or F, Form 1065 or Schedule K1, or Form 941."
    );

  if (field === "total_current_employees")
    return "Enter the number of full-time, part-time, and seasonal employees as of today.";

  if (field === "part_time_employees")
    return "Enter the number of employees who are not working 30 hours (or full-time equivalent hours) per week.";

  // Eligible Employees
  if (field === "qualified_waivers")
    return "Enter the number of employees with qualified waivers, such as being enrolled in Spousal Coverage or Medicare.";

  if (field === "continuants")
    return "Enter the number of employees enrolled in COBRA or state continuation.";

  // Participation and Status
  if (field === "net_enrolled_employees")
    return "Enter the number of employees enrolled in the medical plan.";

  // Contribution Rate

  // Calculated Fields............
  // Total Employees
  if (field === "full_time_employees")
    return "This is the total number of current employees, less part-time employees.";

  // Eligible Employees
  if (field === "net_eligible_employees")
    return "This is the total number of eligible employees, less any qualified waivers, plus any continuants.";

  // Participation and Status
  if (field === "percentage_participating_plan")
    return "This is the number of enrolled employees divided by the number of net eligible employees.";

  if (field === "continuation_status")
    return "If an employer has 20 or more employees during a calendar year, COBRA applies from the first day of January for the following calendar year. If an employer currently subject to COBRA falls below 20 employees, they must offer COBRA through the end of that calendar year. Please refer to the U.S. Department of Labor for additional info: https://www.dol.gov/general/topic/health-plans/cobra";

  if (field === "medicare_status")
    return "The MEWA is Medicare Secondary unless the employer has fewer than 20 employees and has been granted the Small Employer Exception by the CMS.";

  // Contribution Rate

  if (field === "employer_contribution_percentage")
    return "This is the percentage of premium paid by the employer.";
}

export function getMCFChambers() {
  return [
    "Affton-Lemay Chamber of Commerce",
    "Arcadia Valley Chamber of Commerce",
    "Arnold Chamber of Commerce",
    "Asian American Chamber of Commerce (AACC) of St. Louis",
    "Aurora Chamber of Commerce",
    "Barton County Chamber of Commerce",
    "Belton Chamber of Commerce",
    "Bethany Area Chamber of Commerce",
    "Bloomfield Chamber of Commerce, Inc.",
    "Blue Springs Chamber of Commerce",
    "Bolivar Area Chamber of Commerce",
    "Bollinger County Chamber of Commerce",
    "Bonne Terre Chamber of Commerce",
    "Boonville Area Chamber of Commerce",
    "Bowling Green Chamber of Commerce",
    "Branson/Lakes Area Chamber of Commerce and CVB",
    "Brookfield Chamber of Commerce",
    "Buckner Chamber of Commerce",
    "Buffalo Area Chamber of Commerce",
    "Butler Chamber of Commerce",
    "California Area Chamber of Commerce",
    "Callaway Chamber of Commerce ",
    "Camdenton Area Chamber of Commerce",
    "Cameron Area Chamber of Commerce",
    "Cape Girardeau Area Chamber of Commerce",
    "Carl Junction Area Chamber of Commerce",
    "Carroll County Chamber of Commerce ",
    "Carthage Chamber of Commerce",
    "Caruthersville Chamber of Commerce",
    "Cassville Area Chamber of Commerce",
    "Centralia Area Chamber of Commerce",
    "Charleston Chamber of Commerce and Tourism",
    "Chesterfield Chamber of Commerce",
    "Chillicothe Area Chamber of Commerce",
    "Columbia Chamber of Commerce",
    "Concordia Area Chamber of Commerce",
    "Cottleville/Weldon Spring Chamber of Commerce",
    "Creve Coeur-Olivette Chamber of Commerce",
    "Cuba Area Chamber of Commerce",
    "Desloge Chamber of Commerce",
    "DeSoto Chamber of Commerce",
    "Dexter Chamber of Commerce",
    "Dixon Area Chamber of Commerce",
    "East Prairie Chamber of Commerce",
    "El Dorado Springs Chamber of Commerce",
    "Eldon Area Chamber of Commerce",
    "Ellington Chamber of Commerce",
    "Eureka Chamber of Commerce",
    "Excelsior Springs Area Chamber of Commerce",
    "Farmington Regional Chamber of Commerce",
    "Fayette Chamber of Commerce",
    "Fenton Area Chamber of Commerce",
    "Gerald Chamber of Commerce",
    "Gladstone Area Chamber of Commerce",
    "Glasgow Chamber of Commerce",
    "Grain Valley Chamber of Commerce",
    "Grandview Chamber of Commerce",
    "Greater Augusta Chamber of Commerce, The",
    "Greater Clinton Area Chamber of Commerce",
    "Greater Kansas City Chamber of Commerce",
    "Greater Maryville Chamber of Commerce",
    "Greater North County Chamber of Commerce",
    "Greater Poplar Bluff Area Chamber of Commerce",
    "Greater St. Louis, Inc. (fka St. Louis Regional Chamber)",
    "Greater West Plains Chamber of Commerce",
    "Greenfield Area Chamber of Commerce",
    "Hannibal Area Chamber of Commerce",
    "Harrisonville Area Chamber of Commerce",
    "HCCSTL (formerly Hispanic Chamber of Commerce of Metropolitan St. Louis)",
    "Heartland St. Louis Black Chamber of Commerce",
    "Hermann Area Chamber of Commerce",
    "Higginsville Chamber of Commerce",
    "Hillsboro Chamber of Commerce",
    "Holden Chamber of Commerce",
    "Hollister Area Chamber of Commerce",
    "Houston Area Chamber of Commerce",
    "Independence Chamber of Commerce",
    "Jackson Area Chamber of Commerce",
    "Jefferson City Area Chamber of Commerce",
    "Joplin Area Chamber of Commerce",
    "Kahoka/Clark County Chamber of Commerce",
    "Kearney Chamber of Commerce",
    "Kennett Chamber of Commerce",
    "Kirksville Area Chamber of Commerce",
    "Kirkwood - Des Peres Area Chamber of Commerce",
    "Knox County Chamber of Commerce",
    "Lake Area Chamber of Commerce ",
    "Lake of The Ozarks West Chamber of Commerce",
    "Lathrop Area Chamber of Commerce",
    "Lawson Chamber of Commerce",
    "Lebanon Area Chamber of Commerce",
    "Lee's Summit Chamber of Commerce",
    "Lexington Area Chamber of Commerce",
    "Liberty Area Chamber of Commerce",
    "Lockwood Chamber of Commerce",
    "Louisiana Chamber of Commerce",
    "Macon Area Chamber of Commerce",
    "Madison County Chamber of Commerce",
    "Mansfield Chamber of Commerce",
    "Marceline Area Chamber of Commerce",
    "Marshall Chamber of Commerce",
    "Marshfield Chamber of Commerce",
    "Marthasville Area Chamber of Commerce",
    "Maryland Heights Chamber of Commerce",
    "McDonald County Chamber of Commerce",
    "Memphis Area Chamber of Commerce",
    "Mexico Area Chamber of Commerce",
    "Mid County Chamber of Commerce",
    "Mid-America LGBT Chamber of Commerce",
    "Milan Chamber of Commerce",
    "Missouri Chamber of Commerce and Industry (aka MCCI or MCC)",
    "Moberly Area Chamber of Commerce",
    "Monett Chamber of Commerce",
    "Monroe City Chamber of Commerce",
    "Montgomery City Chamber of Commerce",
    "Mount Vernon Chamber of Commerce (aka Mt. Vernon)",
    "Mountain Grove Chamber of Commerce",
    "Mountain View Chamber of Commerce",
    "Neosho Area Chamber of Commerce",
    "Nevada/Vernon County Chamber of Commerce",
    "New Haven Chamber of Commerce",
    "New Melle Chamber of Commerce",
    "Nixa Area Chamber of Commerce",
    "North Kansas City Business Council",
    "Northland Regional Chamber of Commerce",
    "Northwest Jefferson County Chamber of Commerce",
    "O'Fallon Chamber of Commerce & Industries",
    "Oak Grove Chamber of Commerce",
    "Oakville Chamber of Commerce",
    "Owensville Chamber of Commerce",
    "Ozark Area Chamber of Commerce",
    "Ozark County Chamber of Commerce",
    "Pacific Chamber of Commerce ",
    "Palmyra Chamber of Commerce",
    "Paris Area Chamber of Commerce",
    "Park Hills - Leadington Chamber of Commerce",
    "Parkville Chamber of Commerce",
    "Peculiar Area Chamber of Commerce",
    "Perry Area Chamber of Commerce",
    "Perryville Chamber of Commerce",
    "Platte City Area Chamber of Commerce",
    "Plattsburg Chamber of Commerce",
    "Pomme de Terre Chamber of Commerce",
    "Raymore Chamber of Commerce",
    "Raytown Chamber of Commerce & Tourism",
    "Republic Area Chamber of Commerce ",
    "Ripley County Chamber of Commerce",
    "Riverside Area Chamber of Commerce",
    "Rogersville Area Chamber of Commerce",
    "Rolla Area Chamber of Commerce",
    "Salem Area Chamber of Commerce",
    "Salisbury Chamber of Commerce",
    "Sarcoxie Chamber of Commerce",
    "Savannah Chamber of Commerce",
    "Scott City Area Chamber of Commerce",
    "Sedalia Area Chamber of Commerce",
    "Shelby County Chamber of Commerce",
    "Shell Knob Chamber of Commerce",
    "Sikeston Regional Chamber & Area Economic Development Corporation",
    "Smithville Area Chamber of Commerce",
    "South Kansas City Chamber of Commerce",
    "Southern Boone Chamber of Commerce",
    "Southwest Area Chamber of Commerce (f/k/a Crestwood Sunset Hills Chamber of Commerce)",
    "Sparta Area Chamber of Commerce",
    "Springfield Area Chamber of Commerce",
    "St. Charles Regional Chamber",
    "St. Clair Area Chamber of Commerce",
    "St. James Chamber of Commerce",
    "St. Joseph Chamber of Commerce",
    "Ste. Genevieve Chamber of Commerce",
    "Steelville Area Chamber of Commerce",
    "Stockton Area Chamber of Commerce ",
    "Stover Area Chamber of Commerce",
    "Sullivan Area Chamber of Commerce",
    "Sweet Springs Chamber of Commerce",
    "Table Rock Lake Chamber of Commerce",
    "Thayer Missouri Chamber of Commerce",
    "The Chamber of Commerce of Richmond",
    "Town & Country/Frontenac Chamber of Commerce",
    "Trenton Area Chamber of Commerce",
    "Troy Area Chamber of Commerce ",
    "Twin City Area Chamber of Commerce",
    "Union Area Chamber of Commerce ",
    "Van Buren Chamber of Commerce",
    "Vandalia Area Chamber of Commerce",
    "Versailles Chamber of Commerce",
    "Vienna Chamber of Commerce (VCOC)",
    "Warrensburg Chamber of Commerce",
    "Warrenton Area Chamber of Commerce",
    "Warsaw Area Chamber of Commerce",
    "Washington Chamber of Commerce",
    "Washington County Chamber of Commerce",
    "Waynesville-St. Robert Chamber of Commerce",
    "Webb City Area Chamber of Commerce",
    "Webster Groves/Shrewsbury/Rockhill Chamber of Commerce",
    "West St. Louis County Chamber of Commerce",
    "Western St. Charles County Chamber of Commerce",
    "Willard Area Chamber of Commerce",
    "Windsor Area Chamber of Commerce",
    "Wright City Area Chamber of Commerce",
  ];
}
