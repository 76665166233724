import config from "../../config";
import { Auth } from "aws-amplify";

export function getWithURL(url) {
  return new Promise((resolve, reject) => {
    Auth.currentSession().then((session) => {
      const token = session.idToken.jwtToken;
      fetch(config.apiGateway.URL + url, {
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/coreapi+json",
          //"Content-Type": "application/json",
          //"Access-Control-Allow-Origin" : "*"
        }),
        method: "GET",
        mode: "cors",
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            //handleErrors(response.status)
            reject(response.status);
          }
        })
        .then((responseData) => {
          resolve(responseData);
        })
        .catch((err) => {
          //catches anything thrown from
          reject(err);
        });
    });
  });
}

export function getWithURLUnauthenticated(url) {
  return new Promise((resolve, reject) => {
    fetch(config.apiGateway.URL + url, {
      headers: new Headers({
        "Content-Type": "application/coreapi+json",
        //"Content-Type": "application/json",
        //"Access-Control-Allow-Origin" : "*"
      }),
      method: "GET",
      mode: "cors",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          //handleErrors(response.status)
          reject(response.status);
        }
      })
      .then((responseData) => {
        resolve(responseData);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
}
/* COMMENTING UNTIL NEEDED FOR LINTER
function getWithURLUnauthenticatedBody(url, body) {
  return new Promise((resolve, reject) => {
    fetch(config.apiGateway.URL + url, {
      headers: new Headers({
        "Content-Type": "application/coreapi+json",
        "test":"test",
        //"Content-Type": "application/json",
        //"Access-Control-Allow-Origin" : "*"
      }),
      method: "GET",
      mode: "cors",
      body: body
    })
      .then(response => {
        if (response.ok) {
            return response.json();
          } else {
            throw new Error(response);
          }
      })
      .then(responseData => {
        resolve(responseData);
      })
      .catch(err => {
        reject(err);
      })
  });
}
*/
function postWithURL(url, body) {
  return new Promise((resolve, reject) => {
    Auth.currentSession().then((session) => {
      const token = session.idToken.jwtToken;
      fetch(config.apiGateway.URL + url, {
        headers: new Headers({
          Authorization: "Bearer " + token,
          //"Content-Type": "application/coreapi+json"
          "Content-Type": "application/json",
          //"Access-Control-Allow-Origin" : "*"
        }),
        body: body,
        method: "POST",
        mode: "cors",
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            reject(response.status);
          }
        })
        .then((responseData) => {
          resolve(responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  });
}

function postWithURLUnauthenticated(url, body) {
  return new Promise((resolve, reject) => {
    fetch(config.apiGateway.URL + url, {
      headers: new Headers({
        //Authorization: "Bearer " + token,
        //"Content-Type": "application/coreapi+json"
        "Content-Type": "application/json",
        //"Access-Control-Allow-Origin" : "*"
      }),
      body: body,
      method: "POST",
      mode: "cors",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(response);
        }
      })
      .then((responseData) => {
        resolve(responseData);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function postWithURLFile(url, body) {
  return new Promise((resolve, reject) => {
    Auth.currentSession().then((session) => {
      const token = session.idToken.jwtToken;
      fetch(config.apiGateway.URL + url, {
        headers: new Headers({
          Authorization: "Bearer " + token,
          //"Content-Type": "application/coreapi+json"

          //Commenting Content-type for upload...

          "Content-Type": "application/json",
          //"Access-Control-Allow-Origin" : "*"
        }),
        body: body,
        method: "POST",
        mode: "cors",
        responseType: "blob",
      })
        .then((response) => {
          if (response.ok) {
            return response.text();
          } else {
            throw new Error(response);
          }
        })
        .then((responseData) => {
          resolve(responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  });
}
/* COMMENTING UNTIL NEEDED FOR LINTER
function postWithURLUnauthenticatedFile(url, body) {
  return new Promise((resolve, reject) => {
    fetch(config.apiGateway.URL + url, {
      headers: new Headers({
        //Authorization: "Bearer " + token,
        //"Content-Type": "application/coreapi+json"


        //Commenting Content-type for upload...

        "Content-Type": "application/json"
        //"Access-Control-Allow-Origin" : "*"
      }),
      body: body,
      method: "POST",
      mode: "cors",
      responseType:'blob'
    })
      .then(response => {
          return response.text()
      })
      .then(responseData => {
        resolve(responseData);
      })
      .catch(err => {
        reject(err);
      });
  });
}
*/

function postWithURLFileUpload(url, body) {
  return new Promise((resolve, reject) => {
    Auth.currentSession().then((session) => {
      const token = session.idToken.jwtToken;
      fetch(config.apiGateway.URL + url, {
        headers: new Headers({
          Authorization: "Bearer " + token,
          //"Content-Type": "application/coreapi+json"

          //Commenting Content-type for upload...

          //"Content-Type": "application/json"
          //"Access-Control-Allow-Origin" : "*"
        }),
        body: body,
        method: "POST",
        mode: "cors",
        responseType: "blob",
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            reject(response.status);
          }
        })
        .then((responseData) => {
          resolve(responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  });
}

function updateWithURLUnauthenticated(url, body) {
  return new Promise((resolve, reject) => {
    fetch(config.apiGateway.URL + url, {
      headers: new Headers({
        //Authorization: "Bearer " + token,
        //"Content-Type": "application/coreapi+json"
        "Content-Type": "application/json",
        //"Access-Control-Allow-Origin" : "*"
      }),
      body: body,
      method: "PATCH",
      mode: "cors",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          reject(response.status);
        }
      })
      .then((responseData) => {
        resolve(responseData);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
/* COMMENTING UNTIL NEEDED FOR LINTER
function postWithURLUnauthenticatedPublic(url, body) {
  return new Promise((resolve, reject) => {
    fetch(url, {
      headers: new Headers({
        //Authorization: "Bearer " + token,
        //"Content-Type": "application/coreapi+json"
        //"Content-Type": "application/json"
        //"Content-Type": "multipart/form-data",
        //"Access-Control-Allow-Origin" : "*"
      }),
      body: body,
      method: "POST",
      mode: "cors"
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(response);
        }
      })
      .then(responseData => {
        resolve(responseData);
      })
      .catch(err => {
        reject(err);
      });
  });
}
*/
/* COMMENTING UNTIL NEEDED FOR LINTER
function postWithURLForAttachment(url, body) {
  return new Promise((resolve, reject) => {
    Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      fetch(config.apiGateway.URL + url, {
        headers: new Headers({
          Authorization: "Bearer " + token
        }),
        body: body,
        method: "POST",
        mode: "cors"
      })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(response);
          }
        })
        .then(responseData => {
          resolve(responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  });
}
*/

function updateWithURL(url, body) {
  return new Promise((resolve, reject) => {
    Auth.currentSession().then((session) => {
      const token = session.idToken.jwtToken;
      fetch(config.apiGateway.URL + url, {
        headers: new Headers({
          Authorization: "Bearer " + token,
          //"Content-Type": "application/coreapi+json"
          "Content-Type": "application/json",
          //"Access-Control-Allow-Origin" : "*"
        }),
        body: body,
        method: "PATCH",
        mode: "cors",
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            reject(response.status);
          }
        })
        .then((responseData) => {
          resolve(responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  });
}

function deleteWithURL(url) {
  return new Promise((resolve, reject) => {
    Auth.currentSession().then((session) => {
      const token = session.idToken.jwtToken;
      fetch(config.apiGateway.URL + url, {
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        }),
        method: "DELETE",
        mode: "cors",
      })
        .then((response) => {
          if (response.ok) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  });
}

function deleteWithURLUnauthenticated(url) {
  return new Promise((resolve, reject) => {
    fetch(config.apiGateway.URL + url, {
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      method: "DELETE",
      mode: "cors",
    })
      .then((response) => {
        if (response.ok) {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

const api = {
  // Profiles
  getUserInfo: function () {
    var url = "/v1/user";
    return getWithURL(url);
  },
  getUserIdentityID: function () {
    var url = "/v1/user-identity-id";
    return getWithURL(url);
  },
  updateUserFullName: function (body) {
    var url = "/v1/change-name";
    return postWithURL(url, body);
  },
  updateUserEmail: function (body) {
    var url = "/v1/change-email";
    return postWithURL(url, body);
  },
  //Home
  getMEWADashboard: function () {
    let url = "/v1/mewa-portal-dashboard";
    return getWithURL(url);
  },
  // Employers
  getEmployerOptions: function (employerSearchValue) {
    var url = "/v1/employer-search?search_term=" + employerSearchValue;
    return getWithURL(url);
  },
  getEmployer: function (id) {
    var url = "/v1/employers/" + id;
    return getWithURLUnauthenticated(url);
  },
  getEmployerSecured: function (id) {
    var url = "/v1/employers/" + id;
    return getWithURL(url);
  },
  addEmployer: function (body) {
    var url = "/v1/employers";
    return postWithURL(url, body);
  },
  updateEmployerMEWA: function (id, body) {
    var url = "/v1/employers-mewa/" + id;
    return updateWithURL(url, body);
  },
  resendEmployerWelcomeEmailMEWA: function (id) {
    var url = "/v1/employers-mewa-resend-welcome/" + id;
    return postWithURL(url);
  },
  disableSecureEmployerMEWA: function (id) {
    var url = "/v1/employer-secured-disable/" + id;
    return postWithURL(url);
  },
  resendArcEmailMEWA: function (id) {
    var url = "/v1/arc-mewa-resend/" + id;
    return postWithURL(url);
  },
  getArcMEWAEvents: function (id) {
    var url = "/v1/arc-mewa-events/" + id;
    return getWithURL(url);
  },
  getMewaTheme: function () {
    var url = "/v1/mewa-theme";
    return getWithURL(url);
  },
  getMewaContactTheme: function (contactID, mewaID) {
    var url = "/v1/mewa-contact-theme?contact=" + contactID + "&mewa=" + mewaID;
    return getWithURLUnauthenticated(url);
  },
  getMewaBrokerTheme: function (mewaID) {
    var url = "/v1/mewa-broker-theme?mewa=" + mewaID;
    return getWithURLUnauthenticated(url);
  },
  getMewas: function () {
    var url = "/v1/mewas-admin";
    return getWithURL(url);
  },
  sendEmployerPortalLookup: function (body) {
    let url = "/v1/employers-portal-lookup";
    return postWithURLUnauthenticated(url, body);
  },
  getEmployerIsProtected: function (id) {
    var url = "/v1/employers-protected/" + id;
    return getWithURLUnauthenticated(url);
  },
  updateEmployerTOS(id) {
    var url = "/v1/employers-tos/" + id;
    return postWithURLUnauthenticated(url, JSON.stringify({}));
  },
  getEmployerDashboard: function (portalID) {
    let url = "/v1/employers-portal-dashboard/" + portalID;
    return getWithURLUnauthenticated(url);
  },
  getEmployerDashboardSecured: function (portalID) {
    let url = "/v1/employers-portal-dashboard/" + portalID;
    return getWithURL(url);
  },
  enableEmployerIsProtected: function (id, body) {
    var url = "/v1/employers-protected/" + id;
    return postWithURLUnauthenticated(url, body);
  },
  enableEmployerIsProtectedSecured: function (id, body) {
    var url = "/v1/employers-protected/" + id;
    return postWithURL(url, body);
  },
  resendSecureEmployerPassword: function (id) {
    var url = "/v1/employers-protected-resend/" + id;
    return postWithURLUnauthenticated(url, JSON.stringify({}));
  },
  requestPasswordResetEmployer: function (id, body) {
    var url = "/v1/employer-request-password-reset/" + id;
    return postWithURLUnauthenticated(url, body);
  },
  acceptPasswordResetEmployer: function (id) {
    var url = "/v1/employer-accept-password-reset/" + id;
    return getWithURLUnauthenticated(url);
  },
  finishPasswordResetEmployer: function (id) {
    var url = "/v1/employer-accept-password-reset/" + id + "?finished=True";
    return getWithURLUnauthenticated(url);
  },
  acceptSecurePortalEmployer: function (id) {
    var url = "/v1/employer-accept-secure-portal/" + id;
    return getWithURLUnauthenticated(url);
  },
  finishSecurePortalEmployer: function (id) {
    var url = "/v1/employer-accept-secure-portal/" + id + "?finished=True";
    return getWithURLUnauthenticated(url);
  },
  resetEmployerPassword: function (id, body) {
    var url = "/v1/employer-mewa-password-reset/" + id;
    return postWithURL(url, body);
  },
  terminateEmployer: function (id, body) {
    var url = "/v1/employer-terminate/" + id;
    return postWithURL(url, JSON.stringify({}));
  },
  setContinuationStatusAndOptOutEmployer: function (id, body) {
    var url = "/v1/employer-set-continuation-status-and-opt-out/" + id;
    return postWithURL(url, body);
  },
  setOptOutEmployer: function (id, body) {
    var url = "/v1/employer-set-opt-out/" + id;
    return postWithURL(url, body);
  },
  deleteEmployer: function (id, body) {
    var url = "/v1/employers-mewa-delete/" + id;
    return postWithURL(url, JSON.stringify({}));
  },
  getMEWAEmployerContinuationStatusLog: function (id) {
    var url = "/v1/employer-mewa-continuation-status-log/" + id;
    return getWithURL(url);
  },
  // ARC
  getArcRequest: function (portalID) {
    var url = "/v1/employers-arc/" + portalID;
    return getWithURLUnauthenticated(url);
  },
  getArcRequestSecured: function (portalID) {
    var url = "/v1/employers-arc/" + portalID;
    return getWithURL(url);
  },
  unverifyArcRequest: function (portalID, body) {
    var url = "/v1/employers-arc-unverify/" + portalID;
    return postWithURLUnauthenticated(url, body);
  },
  unverifyArcRequestSecured: function (portalID, body) {
    var url = "/v1/employers-arc-unverify/" + portalID;
    return postWithURL(url, body);
  },
  updateArcRequest: function (portalID, body) {
    var url = "/v1/employers-arc/" + portalID;
    return updateWithURLUnauthenticated(url, body);
  },
  updateArcRequestSecured: function (portalID, body) {
    var url = "/v1/employers-arc/" + portalID;
    return updateWithURL(url, body);
  },
  getArcRequestAllowedSecured: function (portalID) {
    var url = "/v1/employers-arc-allowed/" + portalID;
    return getWithURL(url);
  },
  getArcRequestAllowed: function (portalID) {
    var url = "/v1/employers-arc-allowed/" + portalID;
    return getWithURLUnauthenticated(url);
  },
  requestArcFormHelpSecured: function (portalID, body) {
    var url = "/v1/employers-arc-help/" + portalID;
    return postWithURL(url, body);
  },
  requestArcFormHelp: function (portalID, body) {
    var url = "/v1/employers-arc-help/" + portalID;
    return postWithURLUnauthenticated(url, body);
  },
  getArcForm: function (id, body) {
    var url = "/v1/employers-arc-request/" + id + "/map";
    return postWithURLUnauthenticated(url, body);
  },
  getArcSignedForm: function (id, body) {
    var url = "/v1/employers-arc-request/" + id + "/signed-form";
    return getWithURLUnauthenticated(url);
  },
  updateArcSignature: function (id, body) {
    var url = "/v1/employers-arc-signature/" + id;
    return postWithURLUnauthenticated(url, body);
  },

  //Continuation
  confirmRequest: function (id) {
    var url = "/v1/employers-confirm-continuation/" + id;
    return postWithURLUnauthenticated(url, null);
  },
  confirmRequestSecured: function (id) {
    var url = "/v1/employers-confirm-continuation/" + id;
    return postWithURL(url, null);
  },
  getContinuationAllowed: function (id, confirmationID) {
    var url = "/v1/employers-continuation-allowed/" + id;
    if (confirmationID)
      url =
        "/v1/employers-continuation-allowed/" +
        id +
        "?confirmationID=" +
        confirmationID;
    return getWithURLUnauthenticated(url);
  },
  getContinuationAllowedSecured: function (id, confirmationID) {
    var url = "/v1/employers-continuation-allowed/" + id;
    if (confirmationID)
      url =
        "/v1/employers-continuation-allowed/" +
        id +
        "?confirmationID=" +
        confirmationID;
    return getWithURL(url);
  },
  mapContinuationAttestation: function (portalID, body) {
    var url = "/v1/employers-map-continuation-attestation/" + portalID;
    return postWithURLUnauthenticated(url, body);
  },
  mapContinuationAttestationSecured: function (portalID, body) {
    var url = "/v1/employers-map-continuation-attestation/" + portalID;
    return postWithURL(url, body);
  },
  mapSignedContinuationAttestation: function (portalID, body) {
    var url = "/v1/employers-map-signed-continuation-attestation/" + portalID;
    return postWithURLUnauthenticated(url, body);
  },
  mapSignedContinuationAttestationSecured: function (portalID, body) {
    var url = "/v1/employers-map-signed-continuation-attestation/" + portalID;
    return postWithURL(url, body);
  },
  getSupportingDocumentationPresigned: function (id, body) {
    var url = "/v1/employers-supporting-documentation-presigned/" + id;
    return postWithURLUnauthenticated(url, body);
  },
  getSupportingDocumentationPresignedSecured: function (id, body) {
    var url = "/v1/employers-supporting-documentation-presigned/" + id;
    return postWithURL(url, body);
  },
  updateChangeRequest: function (id, body) {
    var url = "/v1/employers-change-request/" + id;
    return updateWithURLUnauthenticated(url, body);
  },
  updateChangeRequestSecured: function (id, body) {
    var url = "/v1/employers-change-request/" + id;
    return updateWithURL(url, body);
  },
  cancelChangeRequest: function (id) {
    var url = "/v1/employers-cancel-change-request/" + id;
    return postWithURLUnauthenticated(url, null);
  },
  cancelChangeRequestSecured: function (id) {
    var url = "/v1/employers-cancel-change-request/" + id;
    return postWithURL(url, null);
  },
  // Documents
  getDocuments: function (id) {
    var url = "/v1/employers/" + id + "/documents";
    return getWithURLUnauthenticated(url);
  },
  getDocumentsSecured: function (id) {
    var url = "/v1/employers/" + id + "/documents";
    return getWithURL(url);
  },
  addDocument: function (id, body) {
    var url = "/v1/employers/" + id + "/documents";
    return postWithURL(url, body);
  },

  deleteDocument: function (documentID) {
    var url = "/v1/documents/" + documentID;
    return deleteWithURL(url);
  },
  getSupportingDocumentationARCPresigned: function (id, body) {
    var url = "/v1/employers-supporting-documentation-arc-presigned/" + id;
    return postWithURLUnauthenticated(url, body);
  },
  getSupportingDocumentationARCPresignedSecured: function (id, body) {
    var url = "/v1/employers-supporting-documentation-arc-presigned/" + id;
    return postWithURL(url, body);
  },

  //Broker MEWA
  getBrokerTeamEmployerMEWA: function (portalID) {
    let url = "/v1/broker-portal-team-mewa/" + portalID;
    return getWithURL(url);
  },
  sendPortalLinkMEWA: function (id) {
    var url = "/v1/employer-mewa-send-portal-link/" + id;
    return postWithURL(url);
  },
  lockBrokerAccountMEWA: function (id) {
    var url = "/v1/broker-portal-lock-broker/" + id;
    return postWithURL(url);
  },
  resetBrokerPasswordMEWA: function (id, body) {
    var url = "/v1/broker-portal-mewa-password-reset/" + id;
    return postWithURL(url, body);
  },
  resendBrokerInviteMEWA: function (brokerID) {
    var url = "/v1/broker-portal-resend-invite-mewa/" + brokerID;
    return postWithURL(url);
  },
  // Medicare MEWA
  addMedicareMEWA: function (body) {
    var url = "/v1/medicare-mewa";
    return postWithURL(url, body);
  },
  updateMedicareMEWA: function (medicareID, body) {
    var url = "/v1/medicare-mewa/" + medicareID;
    return updateWithURL(url, body);
  },
  deleteMedicareMEWA: function (medicareID) {
    var url = "/v1/medicare-mewa/" + medicareID;
    return deleteWithURL(url);
  },
  getMedicareMEWAEvents: function (medicareID) {
    var url = "/v1/medicare-mewa-events/" + medicareID;
    return getWithURL(url);
  },
  resendMedicareEmail: function (medicareID) {
    var url = "/v1/medicare-mewa-resend/" + medicareID;
    return postWithURL(url);
  },
  getMedicareForm: function (id) {
    var url = "/v1/medicare/" + id + "/map";
    return getWithURLUnauthenticated(url);
  },
  getMedicareFormGroup: function (body) {
    var url = "/v1/medicare-map";
    return postWithURL(url, body);
  },
  uploadMedicareFile: function (body) {
    var url = "/v1/medicare-load";
    return postWithURLFileUpload(url, body);
  },
  getMedicareSubmissionMEWAPresigned: function (medicareSubmissionID) {
    var url = "/v1/medicare-submission-mewa-presigned/" + medicareSubmissionID;
    return getWithURL(url);
  },
  getMedicareSubmissionRecordsMEwA: function (medicareSubmissionID) {
    var url = "/v1/medicare-submission-records-mewa/" + medicareSubmissionID;
    return getWithURL(url);
  },
  getMedicareSubmissionNotesMEWAPresignedURL: function (medicareID, body) {
    var url = "/v1/medicare-submission-notes-mewa-presigned/" + medicareID;
    return postWithURL(url, body);
  },
  getMedicareSubmissionAcceptanceMEWAPresignedURL: function (medicareID) {
    var url = "/v1/medicare-submission-acceptance-mewa-presigned/" + medicareID;
    return getWithURL(url);
  },
  getMedicareSubmissionNotesMEwA: function (medicareID, submissionID) {
    var url =
      "/v1/medicare-submission-notes-mewa/" +
      medicareID +
      "?submission_id=" +
      submissionID;
    return getWithURL(url);
  },
  addMedicareSubmissionNoteMEWA: function (medicareSubmissionID, body) {
    var url = "/v1/medicare-submission-notes-mewa/" + medicareSubmissionID;
    return postWithURL(url, body);
  },
  // Contact MEWA
  getBrokerContactTeam: function (contactID) {
    var url = "/v1/broker-contact-mewa-team?contactID=" + contactID;
    return getWithURL(url);
  },

  // Arc Request MEWA
  updateARCRequestMEWA: function (id, body) {
    var url = "/v1/arc-request/" + id;
    return updateWithURL(url, body);
  },
  editARCContactMEWA: function (id, body) {
    var url = "/v1/arc-request-edit-contact/" + id;
    return postWithURL(url, body);
  },
  getSupportingDocumentationARCPresignedMEWA: function (body) {
    var url = "/v1/arc-supporting-documentation-presigned-mewa";
    return postWithURL(url, body);
  },
  //Broker Portal (agency) MEWA
  getApproveNewBroker: function (id, response) {
    var url =
      "/v1/approve-new-broker?id=" +
      id +
      "&result=" +
      encodeURIComponent(response);
    return getWithURL(url);
  },
  // Continuation MEWA
  getSupportingDocumentationList: function (changeRequestID) {
    var url = "/v1/supporting-documentation-mewa/" + changeRequestID;
    return getWithURL(url);
  },
  downloadSupportingDocument: function (supportingDocumentID) {
    var url = "/v1/download-supporting-document-mewa/" + supportingDocumentID;
    return getWithURL(url);
  },
  updateChangeRequestMEWA: function (changeRequestID, body) {
    var url = "/v1/change-request-mewa/" + changeRequestID;
    return updateWithURL(url, body);
  },
  // Medicare Portal
  addMedicarePortal: function (body) {
    var url = "/v1/medicare-portal";
    return postWithURLUnauthenticated(url, body);
  },
  getMedicarePortal: function (medicareID) {
    var url = "/v1/medicare-portal/" + medicareID;
    return getWithURLUnauthenticated(url);
  },
  getMedicarePortalSecured: function (medicareID) {
    var url = "/v1/medicare-portal/" + medicareID;
    return getWithURL(url);
  },
  getMedicareSubmissionPortal: function (medicareID) {
    var url = "/v1/medicare-submission-portal/" + medicareID;
    return getWithURLUnauthenticated(url);
  },
  getMedicareSubmissionPortalSecured: function (medicareID) {
    var url = "/v1/medicare-submission-portal/" + medicareID;
    return getWithURL(url);
  },
  getMedicareSubmissionAcceptancePresignedURLPortal: function (medicareID) {
    var url =
      "/v1/medicare-submission-acceptance-presigned-portal/" + medicareID;
    return getWithURLUnauthenticated(url);
  },
  getMedicareSubmissionAcceptancePresignedURLPortalSecured: function (
    medicareID
  ) {
    var url =
      "/v1/medicare-submission-acceptance-presigned-portal/" + medicareID;
    return getWithURL(url);
  },
  updateMedicarePortal: function (medicareID, body) {
    var url = "/v1/medicare-portal/" + medicareID;
    return updateWithURLUnauthenticated(url, body);
  },
  updateMedicarePortalSecured: function (medicareID, body) {
    var url = "/v1/medicare-portal/" + medicareID;
    return updateWithURL(url, body);
  },
  updateMedicareSignature: function (medicareID) {
    var url = "/v1/medicare-signature/" + medicareID;
    return getWithURLUnauthenticated(url);
  },
  updateMedicareSignatureSecured: function (medicareID) {
    var url = "/v1/medicare-signature/" + medicareID;
    return getWithURL(url);
  },
  addMedicareSignedForm: function (medicareID) {
    var url = "/v1/medicare-signed-form/" + medicareID;
    return postWithURLUnauthenticated(url);
  },
  addMedicareSignedFormSecured: function (medicareID) {
    var url = "/v1/medicare-signed-form/" + medicareID;
    return postWithURL(url);
  },
  // Termination MEWA
  getTerminationAuthForms: function (body) {
    var url = "/v1/termination-download-auth-forms";
    return postWithURL(url, body);
  },
  getTerminationRecords: function (body) {
    var url = "/v1/termination-download";
    return postWithURLFile(url, body);
    //return postWithURLUnauthenticated(url, body);
  },
  addTerminationMEWA: function (body) {
    var url = "/v1/termination-mewa";
    return postWithURL(url, body);
  },
  addTerminationMEWANoPerm: function (body) {
    var url = "/v1/termination-mewa-no-perm";
    return postWithURL(url, body);
  },
  updateTerminationMEWA: function (terminationID, body) {
    var url = "/v1/termination-mewa/" + terminationID;
    return updateWithURL(url, body); //UPDATERPERMISSION
  },
  deleteTerminationMEWA: function (terminationID) {
    var url = "/v1/termination-mewa/" + terminationID;
    return deleteWithURL(url);
  },
  getTerminationMEWAEvents: function (terminationID) {
    var url = "/v1/termination-mewa-events/" + terminationID;
    return getWithURL(url);
  },
  resendTerminationEmail: function (terminationID) {
    var url = "/v1/termination-mewa-resend/" + terminationID;
    return postWithURL(url);
  },
  uploadTerminationFile: function (body) {
    var url = "/v1/termination-load";
    return postWithURLFileUpload(url, body);
  },
  getTerminationForm: function (id) {
    var url = "/v1/termination/" + id + "/map";
    return getWithURLUnauthenticated(url);
  },
  getEmployerMEWAEvents: function (portalID) {
    var url = "/v1/employer-mewa-events/" + portalID;
    return getWithURL(url);
  },
  //SAR Portal
  updateSARRecordOpen: function (portalID, body) {
    var url = "/v1/employer-open-sar/" + portalID;
    return postWithURLUnauthenticated(url, body);
  },
  updateSARRecordOpenSecured: function (portalID, body) {
    var url = "/v1/employer-open-sar/" + portalID;
    return postWithURL(url, body);
  },
  //1095 Portal
  update1095RecordOpen: function (portalID, body) {
    var url = "/v1/employer-open-1095/" + portalID;
    return postWithURLUnauthenticated(url, body);
  },
  update1095RecordOpenSecured: function (portalID, body) {
    var url = "/v1/employer-open-1095/" + portalID;
    return postWithURL(url, body);
  },
  confirm1095RecordInfo: function (_1095ID) {
    var url = "/v1/employer-confirm-1095-info/" + _1095ID;
    return getWithURLUnauthenticated(url);
  },
  confirm1095RecordInfoSecured: function (_1095ID) {
    var url = "/v1/employer-confirm-1095-info/" + _1095ID;
    return getWithURL(url);
  },
  confirm1095Record: function (portalID, body) {
    var url = "/v1/employer-confirm-1095/" + portalID;
    return postWithURLUnauthenticated(url, body);
  },
  confirm1095RecordSecured: function (portalID, body) {
    var url = "/v1/employer-confirm-1095/" + portalID;
    return postWithURL(url, body);
  },

  // Termination Portal
  addTerminationPortal: function (body) {
    var url = "/v1/termination-portal";
    return postWithURLUnauthenticated(url, body);
  },
  getTerminationPortal: function (terminationID) {
    var url = "/v1/termination-portal/" + terminationID;
    return getWithURLUnauthenticated(url);
  },
  getTerminationPortalSecured: function (terminationID) {
    var url = "/v1/termination-portal/" + terminationID;
    return getWithURL(url);
  },
  getTerminationPortalAdditionalInfo: function (terminationID) {
    var url = "/v1/termination-portal-additional-info/" + terminationID;
    return getWithURLUnauthenticated(url);
  },
  getTerminationPortalAdditionalInfoSecured: function (terminationID) {
    var url = "/v1/termination-portal-additional-info/" + terminationID;
    return getWithURL(url);
  },
  updateTerminationPortal: function (terminationID, body) {
    var url = "/v1/termination-portal/" + terminationID;
    return updateWithURLUnauthenticated(url, body);
  },
  updateTerminationPortalSecured: function (terminationID, body) {
    var url = "/v1/termination-portal/" + terminationID;
    return updateWithURL(url, body);
  },
  updateTerminationConfirmAllPortal: function (terminationID) {
    var url = "/v1/termination-portal-confirm-all/" + terminationID;
    return getWithURLUnauthenticated(url);
  },
  updateTerminationConfirmAllPortalSecured: function (terminationID) {
    var url = "/v1/termination-portal-confirm-all/" + terminationID;
    return getWithURL(url);
  },
  addTerminationSignedForm: function (terminationID) {
    var url = "/v1/termination-signed-form/" + terminationID;
    return postWithURLUnauthenticated(url);
  },
  updateTerminationSignature: function (terminationID) {
    var url = "/v1/termination-signature/" + terminationID;
    return getWithURLUnauthenticated(url);
  },
  addTeriminationSignedFormSecured: function (terminationID) {
    var url = "/v1/termination-signed-form" + terminationID;
    return postWithURL(url);
  },
  //Invite Employer Portal
  getEmployerBrokerList: function (portalID) {
    let url = "/v1/employers-list-broker/" + portalID;
    return getWithURLUnauthenticated(url);
  },
  getEmployerBrokerListSecured: function (portalID) {
    let url = "/v1/employers-list-broker/" + portalID;
    return getWithURL(url);
  },
  getInvitationsListEmployerSecured: function (portalID) {
    let url = "/v1/employers-invite-list/" + portalID;
    return getWithURL(url);
  },
  getInvitationsListEmployer: function (portalID) {
    let url = "/v1/employers-invite-list/" + portalID;
    return getWithURLUnauthenticated(url);
  },
  resendEmployerInvite: function (invitationID) {
    var url = "/v1/employer-resend-invite/" + invitationID;
    return postWithURLUnauthenticated(url);
  },
  resendEmployerInviteSecured: function (invitationID) {
    var url = "/v1/employer-resend-invite/" + invitationID;
    return postWithURL(url);
  },
  getBrokerEmployerInvite: function (invitationID) {
    let url = "/v1/employers-invite/" + invitationID;
    return getWithURLUnauthenticated(url);
  },
  getBrokerEmployerInviteSecured: function (invitationID) {
    let url = "/v1/employers-invite/" + invitationID;
    return getWithURL(url);
  },
  updateBrokerEmployerInvite: function (invitationID, body) {
    let url = "/v1/employers-invite/" + invitationID;
    return updateWithURLUnauthenticated(url, body);
  },
  updateBrokerEmployerInviteSecured: function (invitationID, body) {
    let url = "/v1/employers-invite/" + invitationID;
    return updateWithURL(url, body);
  },
  deleteEmployerBrokerInvite: function (inviteID) {
    var url = "/v1/employers-invite/" + inviteID;
    return deleteWithURLUnauthenticated(url);
  },
  deleteEmployerBrokerInviteSecured: function (inviteID) {
    var url = "/v1/employers-invite/" + inviteID;
    return deleteWithURL(url);
  },
  getBrokerEmployerContactChange: function (changeID) {
    let url = "/v1/employers-contact-change/" + changeID;
    return getWithURLUnauthenticated(url);
  },
  getBrokerEmployerContactChangeSecured: function (changeID) {
    let url = "/v1/employers-contact-change/" + changeID;
    return getWithURL(url);
  },
  updateBrokerEmployerContactChange: function (changeID, body) {
    let url = "/v1/employers-contact-change/" + changeID;
    return updateWithURLUnauthenticated(url, body);
  },
  updateBrokerEmployerContactChangeSecured: function (changeID, body) {
    let url = "/v1/employers-contact-change/" + changeID;
    return updateWithURL(url, body);
  },
  addEmployerBrokerInvite: function (portalID, body) {
    let url = "/v1/employers-invite-broker/" + portalID;
    return postWithURLUnauthenticated(url, body);
  },
  addEmployerBrokerInviteSecured: function (portalID, body) {
    let url = "/v1/employers-invite-broker/" + portalID;
    return postWithURL(url, body);
  },
  resendBrokerInvite: function (invitationID) {
    var url = "/v1/broker-resend-invite/" + invitationID;
    return postWithURL(url);
  },
  editBrokerContact: function (brokerID, body) {
    var url = "/v1/broker-portal-edit-contact/" + brokerID;
    return postWithURL(url, body);
  },
  //manage broker permissions
  updateEmployerBrokerRelationship: function (relationshipID, body) {
    let url = "/v1/employers-broker-detail/" + relationshipID;
    return updateWithURLUnauthenticated(url, body);
  },
  updateEmployerBrokerRelationshipSecured: function (relationshipID, body) {
    let url = "/v1/employers-broker-detail/" + relationshipID;
    return updateWithURL(url, body);
  },
  deleteEmployerBrokerRelationship: function (relationshipID) {
    let url = "/v1/employers-broker-detail/" + relationshipID;
    return deleteWithURLUnauthenticated(url);
  },
  deleteEmployerBrokerRelationshipSecured: function (relationshipID) {
    let url = "/v1/employers-broker-detail/" + relationshipID;
    return deleteWithURL(url);
  },

  //Broker portal
  requestAccountLink: function (body) {
    let url = "/v1/broker-portal-request-account";
    return postWithURLUnauthenticated(url, body);
  },
  setBrokerSubscribed: function (body) {
    let url = "/v1/broker-set-subscribed";
    return postWithURL(url, body);
  },
  getBrokerTOS: function () {
    let url = "/v1/broker-tos";
    return getWithURL(url);
  },
  setBrokerTOS: function () {
    let url = "/v1/broker-tos";
    return postWithURL(url);
  },
  getBrokerDashboard: function () {
    let url = "/v1/broker-portal-dashboard";
    return getWithURL(url);
  },
  getBrokerTeam: function () {
    let url = "/v1/broker-portal-team";
    return getWithURL(url);
  },
  getBrokerTeamEmployer: function (portalID) {
    let url = "/v1/broker-portal-team/" + portalID;
    return getWithURL(url);
  },
  getInvitationsListBroker: function () {
    let url = "/v1/brokers-invite-list";
    return getWithURL(url);
  },
  getInvitationsListReviewBroker: function () {
    let url = "/v1/brokers-invite-list-review";
    return getWithURL(url);
  },
  getBrokerInviteEmployerOptions: function (employerSearchValue) {
    var url =
      "/v1/broker-portal-invite-employer-search?search_term=" +
      employerSearchValue;
    return getWithURL(url);
  },
  getBrokerPermissions: function (body) {
    var url = "/v1/broker-portal-broker-permissions";
    return postWithURL(url, body);
  },
  addBrokerBrokerInvite: function (body) {
    var url = "/v1/broker-portal-invite-broker";
    return postWithURL(url, body);
  },
  assignBrokerPermissions: function (body) {
    var url = "/v1/broker-portal-assign-broker";
    return postWithURL(url, body);
  },
  acceptBrokerInvite: function (id) {
    var url = "/v1/brokers-accept-broker-invite/" + id;
    return getWithURLUnauthenticated(url);
  },
  getCreateBrokerAccount: function (brokerID) {
    var url = "/v1/broker-portal-create-account/" + brokerID;
    return getWithURLUnauthenticated(url);
  },
  requestPasswordResetBroker: function (body) {
    var url = "/v1/broker-request-password-reset";
    return postWithURLUnauthenticated(url, body);
  },
  acceptPasswordResetBroker: function (id) {
    var url = "/v1/broker-accept-password-reset/" + id;
    return getWithURLUnauthenticated(url);
  },
  finishPasswordResetBroker: function (id) {
    var url = "/v1/broker-accept-password-reset/" + id + "?finished=True";
    return getWithURLUnauthenticated(url);
  },
  addBrokerEmployerInvite: function (body, employerEmail) {
    var url =
      "/v1/broker-portal-invite-employer?employer_email=" +
      encodeURIComponent(employerEmail);
    return postWithURL(url);
  },
  addBrokerEmployerInviteNewContact: function (body) {
    var url = "/v1/broker-portal-invite-employer-new-contact";
    return postWithURL(url, body);
  },
  updateBrokerInvite: function (inviteID, body) {
    var url = "/v1/brokers-invite/" + inviteID;
    return updateWithURL(url, body);
  },
  updateBrokerInviteNoAccount: function (inviteID, body) {
    var url = "/v1/brokers-invite/" + inviteID;
    return updateWithURLUnauthenticated(url, body);
  },
  deleteBrokerInvite: function (inviteID) {
    var url = "/v1/brokers-invite/" + inviteID;
    return deleteWithURL(url);
  },
  getBrokerTerminationEvents: function (terminationID) {
    var url = "/v1/broker-portal-termination-events/" + terminationID;
    return getWithURL(url);
  },
  resendBrokerTerminationEmail: function (terminationID) {
    var url = "/v1/broker-portal-termination-resend/" + terminationID;
    return postWithURL(url);
  },
  updateTerminationBroker: function (terminationID, body) {
    var url = "/v1/broker-portal-termination/" + terminationID;
    return updateWithURL(url, body);
  },
  getBrokerMedicareEvents: function (medicareID) {
    var url = "/v1/broker-portal-medicare-events/" + medicareID;
    return getWithURL(url);
  },
  sendPortalLinkBroker: function (id) {
    var url = "/v1/broker-portal-send-portal-link/" + id;
    return postWithURL(url);
  },
  resendEmployerWelcomeEmailBroker: function (id) {
    var url = "/v1/broker-portal-resend-welcome/" + id;
    return postWithURL(url);
  },
  getDocumentsBroker: function (id) {
    var url = "/v1/broker-portal-employers/" + id + "/documents";
    return getWithURL(url);
  },
  getBrokerEmployersManage: function () {
    var url = "/v1/broker-portal-employers-manage";
    return getWithURL(url);
  },
  brokerEmployersManageRemoveRelationship: function (body) {
    var url = "/v1/broker-portal-employers-manage";
    return postWithURL(url, body);
  },
  // Broker Contact Portal
  getBrokerContacts: function (contactID) {
    var url = "/v1/broker-contact?contactID=" + contactID;
    return getWithURLUnauthenticated(url);
  },
  addBrokerContact: function (contactID, body) {
    var url = "/v1/broker-contact?contactID=" + contactID;
    return postWithURLUnauthenticated(url, body);
  },
  deleteBrokerContact: function (contactID) {
    var url = "/v1/contact/" + contactID;
    return deleteWithURLUnauthenticated(url);
  },
  getBrokerArcRequest: function (arcRequestID) {
    var url = "/v1/broker-arc/" + arcRequestID;
    return getWithURLUnauthenticated(url);
  },
  updateBrokerArcRequest: function (arcRequestID, body) {
    var url = "/v1/broker-arc/" + arcRequestID;
    return updateWithURLUnauthenticated(url, body);
  },
  updateARCRequestBrokerPortal: function (id, body) {
    var url = "/v1/broker-portal-arc-request/" + id;
    return updateWithURL(url, body);
  },
  resendARCRequestBrokerPortal: function (id) {
    var url = "/v1/broker-portal-arc-request-resend/" + id;
    return postWithURL(url);
  },
  getARCRequestEventsBrokerPortal: function (id) {
    var url = "/v1/broker-portal-arc-request-events/" + id;
    return getWithURL(url);
  },
  getSupportingDocumentationARCPresignedBrokerPortal: function (body) {
    var url = "/v1/broker-portal-supporting-documentation-arc-presigned";
    return postWithURL(url, body);
  },
  getSupportingDocumentationARCPresignedBroker: function (body) {
    var url = "/v1/broker-supporting-documentation-arc-presigned";
    return postWithURLUnauthenticated(url, body);
  },
  getMedicareSubmissionAcceptancePresignedBrokerPortal: function (medicareID) {
    var url =
      "/v1/broker-portal-medicare-submission-acceptance-presigned/" +
      medicareID;
    return getWithURL(url);
  },
  resendMedicareEmailBrokerPortal: function (medicareID) {
    var url = "/v1/broker-portal-medicare-resend/" + medicareID;
    return postWithURL(url);
  },
  getBrokerEmployerContinuationStatusLog: function (id) {
    var url = "/v1/broker-portal-continuation-status-log/" + id;
    return getWithURL(url);
  },
  // Reporting
  getReportOutput: function (outputID) {
    var url = "/v1/report-output/" + outputID;
    return getWithURLUnauthenticated(url);
  },

  // Table Function
  getTableData: function (dataURL) {
    var url = "/v1/" + dataURL;
    return getWithURL(url);
  },
  getTableDataPortal: function (dataURL) {
    var url = "/v1/" + dataURL;
    return getWithURLUnauthenticated(url);
  },
  getTableDataPortalSecured: function (dataURL) {
    var url = "/v1/" + dataURL;
    return getWithURL(url);
  },
  getTableCount: function (countURL) {
    var url = "/v1/" + countURL;
    return getWithURL(url);
  },
  getStaticFilterValues: function (staticFilterURL, body) {
    var url = "/v1/" + staticFilterURL;
    return postWithURL(url, body);
  },
};

export default api;
