import React from "react";
import api from "../../Shared/Functions/APIHelpers";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import BusinessIcon from "@material-ui/icons/Business";
import MedicareIcon from "@material-ui/icons/LocalHospital";
import TerminationIcon from "@material-ui/icons/Cancel";
import PeopleIcon from "@material-ui/icons/People";
import PersonIcon from "@material-ui/icons/Person";
import Agreement from "../../Shared/Components/Agreement/Agreement";

const initialState = {
  loaded: false,
};

class _1095PassThrough extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidMount() {
    var portalID = this.getPortalID();
    var _1095ID = this.get1095ID();
    this.setState({ portalID: portalID, _1095ID: _1095ID });

    this.update1095Record(portalID, _1095ID).then((r) => {
      this.redirectScreen();
      this.setState({ loaded: true });
    });
  }

  getPortalID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get("portalID") ? params.get("portalID") : null;
  };

  get1095ID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get("1095ID") ? params.get("1095ID") : null;
  };

  update1095Record = (portalID, _1095ID) => {
    let body = {
      _1095ID: _1095ID,
    };
    return new Promise((resolve, reject) => {
      if (this.props.employerIsSecured)
        resolve(
          api.update1095RecordOpenSecured(portalID, JSON.stringify(body))
        );
      else resolve(api.update1095RecordOpen(portalID, JSON.stringify(body)));
    });
  };

  redirectScreen = () => {
    this.props.history.push(
      "/employer/documents" + this.props.history.location.search
    );
  };

  render() {
    const { loaded } = this.state;

    if (!loaded) return null;

    return <React.Fragment>1095</React.Fragment>;
  }
}

export default _1095PassThrough;
