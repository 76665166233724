import React, { useState, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import classnames from "classnames";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import RadioGroup from "@material-ui/core/RadioGroup";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import SupportingDocumentationList from "./SupportingDocumentationList";
import SignedDocumentationList from "./SignedDocumentationList";
import ArcDocumentDialog from "./ArcDocumentDialog";
import Typography from "@material-ui/core/Typography";
import { DropzoneDialog } from "material-ui-dropzone";
import CircularProgress from "@material-ui/core/CircularProgress";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  getHelperText,
  getMCFChambers,
  loadPlacesScript,
  formatGoogleReturnAddress,
} from "./ArcHelpers";
import ArcExemptyEntityHelpDialog from "./ArcExemptyEntityHelpDialog";
import ArcFormContinuationStatusCard from "./ArcFormContinuationStatusCard";
import ArcFormContinuationMismatchDialog from "./ArcFormContinuationMismatchDialog";
import ArcFormParticipationRateWarningDialog from "./ArcFormParticipationRateWarningDialog";
import PhoneIcon from "@material-ui/icons/PhoneAndroid";
import UploadIcon from "@material-ui/icons/CloudUpload";
import MomentUtils from "@date-io/moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import * as moment from "moment";
const styles = (theme) => ({
  fieldContainer: {
    width: 750,
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  helperText: {
    color: theme.palette.primary.main,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -37,
    marginLeft: -37,
    color: "primary",
  },
  wrapper: {
    margin: theme.spacing(1),
    //position: "relative",
  },
  root: {
    //display: "flex",
    textAlign: "right",
    //alignItems: "center",
  },
  documentError: {
    borderColor: theme.palette.error.main,
    borderWidth: "3px",
    borderStyle: "solid",
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 30,
    marginTop: 20,
  },
  addressError: {
    borderColor: theme.palette.error.main,
    borderWidth: "3px",
    borderStyle: "solid",
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 30,
    marginTop: 20,
  },
});

const ArcForm = ({
  arcRequest,
  calculateAutoFillFields,
  updateRequestFieldFocus,
  requestFieldFocus,
  updateRequestField,
  updateDocumentField,
  addUploadedFiles,
  removeFile,
  uploadedFiles,
  save,
  arcRequestSaving,
  errors,
  hideSave,
  hideDocuments,
  classes,
  theme,
}) => {
  useEffect(() => {
    /*
    Removed: https://gitlab.com/reformlabs/mewaio/-/issues/619
    //when mounting load the google places script
    loadPlacesScript(() => {
      //Once library is loaded, configure the automoplete object
      setupGooglePlaceObject(setQuery, autoCompleteRef);
    });
    */
    //Call function to clear chamber name if not found in list of static values
    handleChamberNameInitialState(arcRequest);
  }, []);

  const [supportingDialogOpen, setSupportingDialogOpen] = useState(false);
  //query string for google places query (123 Main Street)
  const [query, setQuery] = useState(arcRequest.street);
  //stores automcomplete object once instantiated
  let autoComplete;
  //use reference to avoid re-renders when value changes
  const autoCompleteRef = useRef(null);
  const [documentDialogOpen, setDocumentDialogOpen] = useState(false);
  const [calloutSignatureDate, setCalloutSignatureDate] = useState(false);
  const [
    continuationStatusMismatchDialogOpen,
    setContinuationStatusMismatchDialogOpen,
  ] = useState(false);
  const [
    participationPercentageWarningDialogOpen,
    setParticipationPercentageWarningDialogOpen,
  ] = useState(false);

  const [exemptyEntityHelpDialogOpen, setExemptyEntityHelpDialogOpen] =
    useState(false);

  const handleChamberNameInitialState = (arcRequest) => {
    //This function will check if the current arcRequest chamber_name
    //is in the list of static values we do nothing.
    //If not found, we will blank out the state variable to force user to
    //select a new value

    //first check if chamber is blank or null, if so do nothing
    if (arcRequest.chamber_name === null || arcRequest.chamber_name === "")
      return;

    //if .find() can find the chamber it will return undefined and
    //evaluate to false. Here we clear the chamber name
    if (!getMCFChambers().find((c) => c === arcRequest.chamber_name)) {
      updateRequestField("chamber_name", "");
    }
  };

  const handleSetCalloutSignature = (clearSignature) => {
    if (clearSignature) {
      updateDocumentField("signature_date", null, () => {
        setTimeout(() => {
          setCalloutSignatureDate(true);
        }, 500);
        setCalloutSignatureDate(false);
      });
    } else {
      setTimeout(() => {
        setCalloutSignatureDate(true);
      }, 500);
      setCalloutSignatureDate(false);
    }
  };
  const getParticipationRate = () => {
    if (arcRequest.percentage_participating_plan) {
      return arcRequest.percentage_participating_plan * 100 + "%";
    } else if (arcRequest.percentage_participating_plan === 0) {
      return "0%";
    } else return "";
  };

  const getEmployerContributionPercentage = () => {
    if (
      arcRequest.employer_contribution_percentage === null ||
      arcRequest.employer_contribution_percentage === ""
    ) {
      return "";
    }
    if (arcRequest.employer_contribution_percentage < 1) {
      return Math.round(arcRequest.employer_contribution_percentage * 100);
    }
    if (arcRequest.employer_contribution_percentage == 1) {
      return 100;
    } else {
      return arcRequest.employer_contribution_percentage;
    }
  };

  const getHelpOrError = (field) => {
    if (requestFieldFocus === field) {
      return getHelperText(field, arcRequest);
    }
    if (errors.includes(field)) return "Required";
    return "";
  };

  const getHelpCalculated = (field) => {
    if (requestFieldFocus === field) {
      return getHelperText(field, arcRequest);
    }
  };

  const showContinuationStatusChanged = (checkType) => {
    if (
      arcRequest.original_continuation_status === "" ||
      !arcRequest.original_continuation_status
    )
      return false;
    if (
      arcRequest.continuation_status === "" ||
      !arcRequest.continuation_status
    )
      return false;
    if (
      checkType === "change" &&
      arcRequest.continuation_status !== arcRequest.original_continuation_status
    )
      return true;
  };
  /*
  const setupGooglePlaceObject = (setQuery, autoCompleteRef) => {
    //Now that javascript library is on the document we will create an
    //object from the google.maps.places library
    //autoComplete is a global variable that we will have acces to
    //in the textbox listener function
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { types: ["address"], componentRestrictions: { country: "us" } }
    );
    autoComplete.setFields(["address_components", "formatted_address"]);

    //when a user selects a new address from the list
    //this listener will fire handlePlaceSelect

    autoComplete.addListener("place_changed", () => {
      handlePlaceSelect(setQuery);
    });
  };

  async function handlePlaceSelect(updateQuery) {
    const addressObject = autoComplete.getPlace();
    //parse return places object
    let googleAddressObject = formatGoogleReturnAddress(addressObject);
    //update state variables using returned address
    //we will store the Street in ARC request and also
    //overwrite the Query so that the textbox only shows
    //street name once selected
    if (googleAddressObject.streetNumber) {
      updateRequestField(
        "street",
        googleAddressObject.streetNumber + " " + googleAddressObject.streetName
      );
      updateQuery(
        googleAddressObject.streetNumber + " " + googleAddressObject.streetName
      );
    } else {
      updateRequestField("street", googleAddressObject.streetName);
      updateQuery(googleAddressObject.streetName);
    }
    if (googleAddressObject.city)
      updateRequestField("city", googleAddressObject.city);
    else updateRequestField("city", "");
    if (googleAddressObject.state)
      updateRequestField("state", googleAddressObject.state);
    else updateRequestField("state", "");

    if (googleAddressObject.zip)
      updateRequestField("zip", googleAddressObject.zip);
    else updateRequestField("zip", "");
    //Clear suite
    updateRequestField("suite", "");
  }
  */
  const updateChamberDate = (date, fieldName) => {
    //if date is null save null field to model
    if (!date) {
      updateRequestField(fieldName, null);
      return;
    }
    //Convert date to moment object
    let momentDate = moment(date);
    //Update request field using the formatted date
    updateRequestField(fieldName, momentDate.format("YYYY-MM-DD"));
  };

  const handleExemptEntityHelp = () => {
    setExemptyEntityHelpDialogOpen(true);
  };

  const getSignFormTooltip = () => {
    if (
      arcRequest.EIN == null ||
      arcRequest.EIN === "" ||
      arcRequest.chamber_name == null ||
      arcRequest.chamber_name === "" ||
      arcRequest.title == null ||
      arcRequest.title === ""
    ) {
      return "Please complete each field to sign";
    } else return "";
  };

  const handleSave = () => {
    if (showParticipiationPercentageWarning()) {
      setParticipationPercentageWarningDialogOpen(true);
    } else if (showContinuationStatusMismatched()) {
      console.log("HERE");
      setContinuationStatusMismatchDialogOpen(true);
    } else {
      setParticipationPercentageWarningDialogOpen(false);
      setContinuationStatusMismatchDialogOpen(false);
      save();
    }
  };

  const showParticipiationPercentageWarning = () => {
    if (!isNaN(arcRequest.percentage_participating_plan)) {
      if (arcRequest.percentage_participating_plan > 1) return true;
    }
  };

  const showContinuationStatusMismatched = () => {
    if (
      !arcRequest.total_current_employees ||
      arcRequest.total_current_employees === ""
    )
      return false;
    if (
      !arcRequest.continuation_status ||
      arcRequest.continuation_status === ""
    )
      return false;

    if (
      arcRequest.continuation_status === "COBRA" &&
      arcRequest.total_current_employees < 19
    )
      return true;

    if (
      arcRequest.continuation_status === "State Continuation" &&
      arcRequest.total_current_employees >= 19
    )
      return true;
  };

  return (
    <React.Fragment>
      {continuationStatusMismatchDialogOpen && (
        <ArcFormContinuationMismatchDialog
          Open={continuationStatusMismatchDialogOpen}
          Close={() => {
            setContinuationStatusMismatchDialogOpen(false);
          }}
          ContinuationStatus={arcRequest.continuation_status}
          Save={() => {
            setContinuationStatusMismatchDialogOpen(false);
            save();
          }}
        />
      )}
      {participationPercentageWarningDialogOpen && (
        <ArcFormParticipationRateWarningDialog
          Open={participationPercentageWarningDialogOpen}
          Close={() => {
            setParticipationPercentageWarningDialogOpen(false);
          }}
          Continue={() => {
            setParticipationPercentageWarningDialogOpen(false);
            if (showContinuationStatusMismatched())
              setContinuationStatusMismatchDialogOpen(true);
            else save();
          }}
        />
      )}
      {/*
      <div
        className={classnames({
          [classes.addressError]:
            errors.includes("street") ||
            errors.includes("city") ||
            errors.includes("state") ||
            errors.includes("zip"),
        })}
      >
        {(errors.includes("street") ||
          errors.includes("city") ||
          errors.includes("state") ||
          errors.includes("zip")) && (
          <Typography
            variant="caption"
            style={{ marginTop: 5, color: theme.palette.error.main }}
          >
            Required
          </Typography>
        )}

        <Typography variant="h5">Employer Mailing Address</Typography>
        <div style={{ marginTop: 20 }}></div>
        <React.Fragment>
          <TextField
            id="street-text-field"
            label="Street"
            error={errors.includes("street") && requestFieldFocus !== "street"}
            inputRef={autoCompleteRef}
            placeholder="Enter Address"
            value={query}
            onChange={(e) => {
              //on change we update the query value and the street name on the ARC request
              setQuery(e.target.value);
              updateRequestField("street", e.target.value);
            }}
            helperText={getHelpOrError("street")}
            FormHelperTextProps={{
              classes: {
                root: classnames({
                  [classes.helperText]: requestFieldFocus === "street",
                }),
              },
            }}
            onFocus={() => {
              updateRequestFieldFocus("street");
            }}
            style={{ marginTop: 15, width: 500 }}
            margin="dense"
            onBlur={calculateAutoFillFields}
            fullWidth
            autoComplete="off"
          />

          <br />
          <TextField
            id="suite-text-field"
            label="Apartment, Unit, Suite, or Floor #"
            error={errors.includes("suite") && requestFieldFocus !== "suite"}
            helperText={getHelpOrError("suite")}
            FormHelperTextProps={{
              classes: {
                root: classnames({
                  [classes.helperText]: requestFieldFocus === "suite",
                }),
              },
            }}
            onFocus={() => {
              updateRequestFieldFocus("suite");
            }}
            margin="dense"
            onBlur={calculateAutoFillFields}
            onChange={(e) => {
              updateRequestField("suite", e.target.value);
            }}
            value={arcRequest.suite != null ? arcRequest.suite : ""}
            fullWidth
            style={{ width: 500 }}
            autoComplete="off"
          />
          <br />
          <TextField
            id="city-text-field"
            label="City"
            error={errors.includes("city") && requestFieldFocus !== "city"}
            helperText={getHelpOrError("city")}
            FormHelperTextProps={{
              classes: {
                root: classnames({
                  [classes.helperText]: requestFieldFocus === "city",
                }),
              },
            }}
            onFocus={() => {
              updateRequestFieldFocus("city");
            }}
            margin="dense"
            onBlur={calculateAutoFillFields}
            onChange={(e) => {
              updateRequestField("city", e.target.value);
            }}
            value={arcRequest.city != null ? arcRequest.city : ""}
            style={{ width: 250 }}
            fullWidth
            autoComplete="off"
          />
          <br />
          <TextField
            id="state-text-field"
            label="State"
            error={errors.includes("state") && requestFieldFocus !== "state"}
            helperText={getHelpOrError("state")}
            FormHelperTextProps={{
              classes: {
                root: classnames({
                  [classes.helperText]: requestFieldFocus === "state",
                }),
              },
            }}
            style={{ width: 50 }}
            onFocus={() => {
              updateRequestFieldFocus("state");
            }}
            margin="dense"
            onBlur={calculateAutoFillFields}
            onChange={(e) => {
              if (e.target.value.length < 3)
                updateRequestField("state", e.target.value);
            }}
            value={arcRequest.state != null ? arcRequest.state : ""}
            autoComplete="off"
          />
          <TextField
            id="zip-text-field"
            label="Zip Code"
            error={errors.includes("zip") && requestFieldFocus !== "zip"}
            helperText={getHelpOrError("zip")}
            FormHelperTextProps={{
              classes: {
                root: classnames({
                  [classes.helperText]: requestFieldFocus === "zip",
                }),
              },
            }}
            onFocus={() => {
              updateRequestFieldFocus("zip");
            }}
            margin="dense"
            onBlur={calculateAutoFillFields}
            onChange={(e) => {
              updateRequestField("zip", e.target.value);
            }}
            value={arcRequest.zip != null ? arcRequest.zip : ""}
            style={{ marginLeft: 20, width: 80 }}
            autoComplete="off"
          />
        </React.Fragment>

        <div style={{ marginTop: 30 }}></div>
      </div>
      */}
      {exemptyEntityHelpDialogOpen && (
        <ArcExemptyEntityHelpDialog
          Open={exemptyEntityHelpDialogOpen}
          Close={() => {
            setExemptyEntityHelpDialogOpen(false);
          }}
        />
      )}
      <Typography variant="h5">Employer or Entity Type</Typography>
      <FormControl
        component="fieldset"
        style={{ marginTop: 10, marginBottom: 20 }}
        error={errors.includes("entity_type")}
      >
        <FormLabel component="legend">
          Are you a sole proprietor, government entity, or church?
          <Button onClick={handleExemptEntityHelp} color="primary">
            Help
          </Button>
        </FormLabel>
        <RadioGroup
          aria-label="exempt-entity"
          name="exempt-entity"
          value={arcRequest.entity_type ? parseInt(arcRequest.entity_type) : -1}
          onChange={(e) => {
            let selectedValue = parseInt(e.target.value);
            updateRequestField("entity_type", selectedValue);
            //always reset has_employees so that if an employer goes from soleprop - another entity type
            //they see all fields properly
            updateRequestField("has_employees", true);
            if (selectedValue === 4) updateRequestField("exempt_entity", false);
            else updateRequestField("exempt_entity", true);
          }}
          onBlur={calculateAutoFillFields}
        >
          <FormControlLabel
            id="sole-proprietor-radio"
            value={1}
            control={<Radio />}
            label="Sole Proprietor"
          />
          <FormControlLabel
            id="government-entity-radio"
            value={2}
            control={<Radio />}
            label="Government Entity"
          />
          <FormControlLabel
            id="church-radio"
            value={3}
            control={<Radio />}
            label="Church"
          />
          <FormControlLabel
            id="none-of-these-radio"
            value={4}
            control={<Radio />}
            label="None of These"
          />
        </RadioGroup>
      </FormControl>
      <br />
      {arcRequest.entity_type === 1 && (
        <FormControl
          component="fieldset"
          style={{ marginTop: 10, marginBottom: 20 }}
          error={errors.includes("has_employees")}
        >
          <FormLabel component="legend">
            Do you have any employees other than yourself?
          </FormLabel>
          <RadioGroup
            aria-label="has-employees"
            name="has-employees"
            value={arcRequest.has_employees ? "yes" : "no"}
            onChange={(e) => {
              updateRequestField(
                "has_employees",
                e.target.value === "yes" ? true : false
              );
            }}
            onBlur={calculateAutoFillFields}
          >
            <FormControlLabel
              id="yes-has-employees-radio"
              value={"yes"}
              control={<Radio />}
              label="Yes"
            />
            <FormControlLabel
              id="no-has-employees-radio"
              value={"no"}
              control={<Radio />}
              label="No"
            />
          </RadioGroup>
        </FormControl>
      )}

      {arcRequest.entity_type === 1 && !arcRequest.has_employees && (
        <div style={{ textAlign: "center", marginTop: 5 }}>
          <Typography variant="body1">
            Because you are exempt, a Form 5500 will not be filed on your
            behalf. Also because you do not have employees, you are not required
            to complete certain sections of the ARC Form. These sections have
            been hidden for you.
          </Typography>
        </div>
      )}
      {(arcRequest.entity_type === 2 || arcRequest.entity_type === 3) && (
        <div style={{ textAlign: "center", marginTop: 20, marginBottom: 25 }}>
          <Typography variant="body1">
            Because you are exempt, a Form 5500 will not be filed on your
            behalf.
          </Typography>
        </div>
      )}

      {arcRequest.has_employees && (
        <React.Fragment>
          <Typography variant="h5">Total Employees</Typography>
          <TextField
            id="total_current_employees-text-field"
            label="Total Current Employees"
            error={
              errors.includes("total_current_employees") &&
              requestFieldFocus !== "total_current_employees"
            }
            helperText={getHelpOrError("total_current_employees")}
            FormHelperTextProps={{
              classes: {
                root: classnames({
                  [classes.helperText]:
                    requestFieldFocus === "total_current_employees",
                }),
              },
            }}
            onFocus={() => {
              updateRequestFieldFocus("total_current_employees");
            }}
            margin="dense"
            onBlur={calculateAutoFillFields}
            onChange={(e) => {
              if (e.target.value.match(/^[0-9]{0,4}$|^$/))
                updateRequestField("total_current_employees", e.target.value);
            }}
            value={
              arcRequest.total_current_employees != null
                ? arcRequest.total_current_employees
                : ""
            }
            fullWidth
            autoComplete="off"
          />

          <TextField
            id="total_eligible_employees-text-field"
            label="Total Eligible Employees"
            error={
              errors.includes("total_eligible_employees") &&
              requestFieldFocus !== "total_eligible_employees"
            }
            helperText={getHelpOrError("total_eligible_employees")}
            FormHelperTextProps={{
              classes: {
                root: classnames({
                  [classes.helperText]:
                    requestFieldFocus === "total_eligible_employees",
                }),
              },
            }}
            onFocus={() => {
              updateRequestFieldFocus("total_eligible_employees");
            }}
            margin="dense"
            onBlur={calculateAutoFillFields}
            onChange={(e) => {
              if (e.target.value.match(/^[0-9]{0,4}$|^$/))
                updateRequestField("total_eligible_employees", e.target.value);
            }}
            value={
              arcRequest.total_eligible_employees != null
                ? arcRequest.total_eligible_employees
                : ""
            }
            fullWidth
            autoComplete="off"
          />

          <TextField
            id="net_enrolled_employees-text-field"
            label="Enrolled Employees"
            error={
              errors.includes("net_enrolled_employees") &&
              requestFieldFocus !== "net_enrolled_employees"
            }
            helperText={getHelpOrError("net_enrolled_employees")}
            FormHelperTextProps={{
              classes: {
                root: classnames({
                  [classes.helperText]:
                    requestFieldFocus === "net_enrolled_employees",
                }),
              },
            }}
            onFocus={() => {
              updateRequestFieldFocus("net_enrolled_employees");
            }}
            margin="dense"
            onBlur={calculateAutoFillFields}
            onChange={(e) => {
              if (e.target.value.match(/^[0-9]{0,4}$|^$/))
                updateRequestField("net_enrolled_employees", e.target.value);
            }}
            value={
              arcRequest.net_enrolled_employees != null
                ? arcRequest.net_enrolled_employees
                : ""
            }
            fullWidth
            autoComplete="off"
          />

          <TextField
            id="qualified_waivers-text-field"
            label="Employees with Qualified Waivers"
            error={
              errors.includes("qualified_waivers") &&
              requestFieldFocus !== "qualified_waivers"
            }
            helperText={getHelpOrError("qualified_waivers")}
            FormHelperTextProps={{
              classes: {
                root: classnames({
                  [classes.helperText]:
                    requestFieldFocus === "qualified_waivers",
                }),
              },
            }}
            onFocus={() => {
              updateRequestFieldFocus("qualified_waivers");
            }}
            margin="dense"
            onBlur={calculateAutoFillFields}
            onChange={(e) => {
              if (e.target.value.match(/^[0-9]{0,4}$|^$/))
                updateRequestField("qualified_waivers", e.target.value);
            }}
            value={
              arcRequest.qualified_waivers != null
                ? arcRequest.qualified_waivers
                : ""
            }
            fullWidth
            autoComplete="off"
          />

          <TextField
            id="continuants-text-field"
            label="Continuants"
            error={
              errors.includes("continuants") &&
              requestFieldFocus !== "continuants"
            }
            helperText={getHelpOrError("continuants")}
            FormHelperTextProps={{
              classes: {
                root: classnames({
                  [classes.helperText]: requestFieldFocus === "continuants",
                }),
              },
            }}
            onFocus={() => {
              updateRequestFieldFocus("continuants");
            }}
            margin="dense"
            onBlur={calculateAutoFillFields}
            onChange={(e) => {
              if (e.target.value.match(/^[0-9]{0,4}$|^$/))
                updateRequestField("continuants", e.target.value);
            }}
            value={arcRequest.continuants != null ? arcRequest.continuants : ""}
            fullWidth
            autoComplete="off"
          />

          <TextField
            id="net_eligible_employees-text-field"
            label="Net Eligible Employees"
            margin="dense"
            error={
              errors.includes("net_eligible_employees") &&
              requestFieldFocus !== "net_eligible_employees"
            }
            helperText={getHelpCalculated("net_eligible_employees")}
            onClick={() => {
              updateRequestFieldFocus("net_eligible_employees");
            }}
            FormHelperTextProps={{
              classes: {
                root: classnames({
                  [classes.helperText]:
                    requestFieldFocus === "net_eligible_employees",
                }),
              },
            }}
            onBlur={calculateAutoFillFields}
            value={
              arcRequest.net_eligible_employees != null
                ? arcRequest.net_eligible_employees
                : ""
            }
            fullWidth
            variant="filled"
            disabled
            autoComplete="off"
          />

          <Typography style={{ marginTop: 40 }} variant="h5">
            Participation and Status
          </Typography>

          <TextField
            id="percentage_participating_plan-text-field"
            label="Participation Rate"
            margin="dense"
            error={
              errors.includes("percentage_participating_plan") &&
              requestFieldFocus !== "percentage_participating_plan"
            }
            helperText={getHelpCalculated("percentage_participating_plan")}
            onClick={() => {
              updateRequestFieldFocus("percentage_participating_plan");
            }}
            FormHelperTextProps={{
              classes: {
                root: classnames({
                  [classes.helperText]:
                    requestFieldFocus === "percentage_participating_plan",
                }),
              },
            }}
            onBlur={calculateAutoFillFields}
            value={getParticipationRate()}
            fullWidth
            variant="filled"
            disabled
            autoComplete="off"
          />

          <Typography variant="h6" gutterBottom />
          <FormControl fullWidth={true}>
            <InputLabel
              id="continuation_status-dropdown"
              htmlFor="continuation_status_select"
            >
              Continuation Status
            </InputLabel>
            <Select
              id="continuation-status_select"
              error={
                errors.includes("continuation_status") &&
                requestFieldFocus !== "continuation_status"
              }
              onClick={() => {
                updateRequestFieldFocus("continuation_status");
              }}
              onBlur={calculateAutoFillFields}
              value={
                arcRequest.continuation_status
                  ? arcRequest.continuation_status
                  : ""
              }
              onChange={(e) =>
                updateRequestField("continuation_status", e.target.value)
              }
            >
              <MenuItem
                id="continuation_status-state-li"
                value={"State Continuation"}
              >
                State Continuation
              </MenuItem>
              <MenuItem id="continuation-status-cobra-li" value={"COBRA"}>
                COBRA
              </MenuItem>
            </Select>
            <FormHelperText
              className={classnames({
                [classes.helperText]:
                  requestFieldFocus === "continuation_status",
              })}
            >
              {getHelpCalculated("continuation_status")}
            </FormHelperText>
          </FormControl>
          {showContinuationStatusChanged("change") && (
            <ArcFormContinuationStatusCard Open={true} />
          )}

          <Typography style={{ marginTop: 40 }} variant="h5">
            Contribution Rate
          </Typography>

          <TextField
            id="employer_contribution_percentage-text-field"
            label="Employer Contribution Percentage"
            error={
              errors.includes("employer_contribution_percentage") &&
              requestFieldFocus !== "employer_contribution_percentage"
            }
            helperText={getHelpOrError("employer_contribution_percentage")}
            FormHelperTextProps={{
              classes: {
                root: classnames({
                  [classes.helperText]:
                    requestFieldFocus === "employer_contribution_percentage",
                }),
              },
            }}
            onFocus={() => {
              updateRequestFieldFocus("employer_contribution_percentage");
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">%</InputAdornment>
              ),
            }}
            margin="dense"
            onBlur={calculateAutoFillFields}
            onChange={(e) => {
              if (e.target.value === "100")
                updateRequestField("employer_contribution_percentage", 1);
              else if (e.target.value.match(/^([0-9]|([1-9][0-9])|100)$/))
                updateRequestField(
                  "employer_contribution_percentage",
                  (e.target.value / 100).toFixed(2)
                );
              else if (e.target.value === "" || e.target.value == null)
                updateRequestField(
                  "employer_contribution_percentage",
                  e.target.value
                );
            }}
            value={getEmployerContributionPercentage()}
            fullWidth
            autoComplete="off"
          />
        </React.Fragment>
      )}

      {arcRequest.arc_mewa === "9ac40fe874e84df5b81288daf45de042" && (
        <React.Fragment>
          <div
            className={classnames({
              [classes.addressError]: errors.includes("chamber_name"),
            })}
          >
            {errors.includes("chamber_name") && (
              <Typography
                variant="caption"
                style={{ marginTop: 5, color: theme.palette.error.main }}
              >
                Required
              </Typography>
            )}
            <Typography style={{ marginTop: 40 }} variant="h5">
              Chamber Info
            </Typography>
            <Typography variant="h6" gutterBottom />

            <FormControl
              fullWidth={true}
              style={{ marginTop: 20, marginBottom: 20 }}
            >
              <InputLabel
                id="chamber_name-dropdown"
                htmlFor="chamber_name_select"
              >
                Chamber Name
              </InputLabel>
              <Select
                id="chamber-name_select"
                error={
                  errors.includes("chamber_name") &&
                  requestFieldFocus !== "chamber_name"
                }
                onClick={() => {
                  updateRequestFieldFocus("chamber_name");
                }}
                onBlur={calculateAutoFillFields}
                value={arcRequest.chamber_name}
                onChange={(e) =>
                  updateRequestField("chamber_name", e.target.value)
                }
              >
                {getMCFChambers().map((chamber, index) => {
                  return (
                    <MenuItem value={chamber} key={index}>
                      {chamber}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText
                className={classnames({
                  [classes.helperText]: requestFieldFocus === "chamber_name",
                })}
              >
                {getHelpCalculated("chamber_name")}
              </FormHelperText>
            </FormControl>
          </div>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
              id="DateOfBirth"
              autoOk={true}
              error={errors.includes("chamber_start_date")}
              value={arcRequest.chamber_start_date}
              onChange={(date) => {
                updateChamberDate(date, "chamber_start_date");
              }}
              openTo="date"
              format="MM/DD/YYYY"
              label="Membership Start Date"
              views={["year", "month", "date"]}
              style={{ marginRight: 15, marginTop: 15 }}
            />
            <KeyboardDatePicker
              id="DateOfBirth"
              autoOk={true}
              error={errors.includes("chamber_end_date")}
              value={arcRequest.chamber_end_date}
              onChange={(date) => {
                updateChamberDate(date, "chamber_end_date");
              }}
              openTo="date"
              style={{ marginTop: 15 }}
              format="MM/DD/YYYY"
              label="Membership End Date"
              views={["year", "month", "date"]}
            />
          </MuiPickersUtilsProvider>
        </React.Fragment>
      )}

      {!hideDocuments && (
        <React.Fragment>
          <div
            className={classnames({
              [classes.documentError]: errors.includes("uploadedFiles"),
            })}
          >
            {errors.includes("uploadedFiles") && (
              <Typography
                variant="caption"
                style={{ marginTop: 5, color: theme.palette.error.main }}
              >
                Required
              </Typography>
            )}
            <Typography variant="h5" style={{ marginTop: 40 }}>
              Supporting Documentation
            </Typography>
            <Typography variant="caption">
              Please upload proof of chamber membership. Acceptable documents
              include:
              <ul>
                <li>
                  A letter from your Chamber of Commerce stating your company is
                  a member in good standing. The letter must be on letterhead
                  specific to your company’s declared Chamber of Commerce.
                </li>
                <li>
                  An email from your Chamber of Commerce stating you are a
                  member in good standing and must contain the sender’s name and
                  title, email address, date and timestamp.
                </li>
                <li>
                  A copy of a cancelled check or credit card receipt that
                  indicates payment of membership dues in the Chamber of
                  Commerce.
                </li>
              </ul>
            </Typography>

            <div>
              <div
                style={{
                  float: "left",
                  height: 40,
                  marginTop: 5,
                  marginRight: 3,
                }}
              >
                <PhoneIcon />
              </div>
              <div>
                <Typography variant="caption">
                  Note: If you access this page on your smartphone, you can use
                  the camera to take a picture of any documentation.
                </Typography>
              </div>
            </div>

            <br />
            <br />
            <DropzoneDialog
              open={supportingDialogOpen}
              onSave={(f) => {
                addUploadedFiles(f);
                setSupportingDialogOpen(false);
              }}
              acceptedFiles={[".pdf", ".doc", ".docx", "image/*", ".HEIC"]}
              maxFileSize={7000000}
              showAlerts={["error"]}
              previewText={"Selected Files: "}
              onClose={() => {
                setSupportingDialogOpen(false);
              }}
              showPreviews={true}
              showPreviewsInDropzone={false}
              useChipsForPreview
              previewGridProps={{
                container: { spacing: 1, direction: "row" },
              }}
              previewChipProps={{ classes: { root: classes.previewChip } }}
              filesLimit={10}
            />
            <SupportingDocumentationList
              uploadedFiles={uploadedFiles}
              removeFile={removeFile}
            />
            <Button
              color="primary"
              onClick={() => {
                setSupportingDialogOpen(true);
              }}
              style={{ marginTop: 10 }}
              startIcon={<UploadIcon />}
              variant="outlined"
            >
              Upload Documentation
            </Button>
          </div>
        </React.Fragment>
      )}
      {arcRequest.require_arc_sig_form && (
        <React.Fragment>
          <div
            className={classnames({
              [classes.documentError]: errors.includes("signature_date"),
            })}
          >
            {errors.includes("signature_date") && (
              <Typography
                variant="caption"
                style={{ marginTop: 5, color: theme.palette.error.main }}
              >
                Required
              </Typography>
            )}
            <Typography variant="h5" style={{ marginTop: 40 }}>
              Acknowledgement Form
            </Typography>
            <Typography variant="caption">
              Please complete these additional fields, then review and sign the
              following acknowledgement from provided by {arcRequest.mewa_name}.
            </Typography>
            <TextField
              id="title-text-field"
              label="Your Title"
              margin="dense"
              onChange={(e) => {
                updateDocumentField("title", e.target.value, null);
                if (arcRequest.signature_date) {
                  handleSetCalloutSignature(true);
                }
              }}
              value={arcRequest.title != null ? arcRequest.title : ""}
              fullWidth
              autoComplete="off"
            />
            {/*
            <TextField
              id="chamber_name-text-field"
              label="Chamber of Commerce"
              margin="dense"
              onChange={(e) => {
                updateDocumentField("chamber_name", e.target.value, null);
                if (arcRequest.signature_date) {
                  handleSetCalloutSignature(true);
                }
              }}
              value={
                arcRequest.chamber_name != null ? arcRequest.chamber_name : ""
              }
              fullWidth
              autoComplete="off"
            />
             */}
            <SignedDocumentationList
              arcRequest={arcRequest}
              calloutSignatureDate={calloutSignatureDate}
            />
            <Tooltip title={getSignFormTooltip()}>
              <span style={{ marginTop: 20 }}>
                <Button
                  color="primary"
                  onClick={() => {
                    setDocumentDialogOpen(true);
                    setCalloutSignatureDate(false);
                  }}
                  startIcon={<BorderColorIcon />}
                  variant="outlined"
                  disabled={
                    arcRequest.chamber_name == null ||
                    arcRequest.chamber_name === "" ||
                    arcRequest.title == null ||
                    arcRequest.title === "" ||
                    arcRequest.signature_date != null
                  }
                >
                  Sign Form
                </Button>
              </span>
            </Tooltip>
            {documentDialogOpen && (
              <ArcDocumentDialog
                Open={documentDialogOpen}
                Close={(signedDocument) => {
                  setDocumentDialogOpen(false);
                  if (signedDocument) {
                    handleSetCalloutSignature(false);
                  }
                }}
                calculateAutoFillFields={calculateAutoFillFields}
                updateRequestField={updateRequestField}
                arcRequest={arcRequest}
              />
            )}
          </div>
        </React.Fragment>
      )}
      {!hideSave && (
        <div className={classes.root}>
          <div className={classes.wrapper}>
            <Button
              onClick={handleSave}
              id="save-arc-button"
              variant="contained"
              color="primary"
              disabled={arcRequestSaving}
              style={{ marginTop: 50, width: 200 }}
            >
              Save
            </Button>
            {arcRequestSaving && (
              <CircularProgress size={75} className={classes.buttonProgress} />
            )}
          </div>
          <div style={{ height: 200 }} />
        </div>
      )}
    </React.Fragment>
  );
};

export default withStyles(styles)(ArcForm);
